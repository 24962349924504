import Modal from "components/Modal/Modal";

export default function OrderRecipientProducts({ recipient, onClose }) {
  let body = (
    <div className="py-4 px-8 grid grid-cols-2 gap-x-4">
      {recipient.products.map((product, index) => {
        let imgUrl =
          process.env.REACT_APP_ENVIRONMENT === "production" ? product.product_image_url : "https://picsum.photos/200";

        return (
          <div key={`recipient-${index}`} className="mb-4  border rounded-lg p-5">
            <div className="flex gap-4">
              <img src={imgUrl} alt={product.product_name} className="w-1/3 h-full rounded-lg mr-2" />
              <div className="w-2/3">
                <h3 className="text-lg font-bold mb-2">{product.product_name}</h3>
                {product.variations.length > 0 && (
                  <div className="flex flex-wrap">
                    {product.variations.map((variation, index) => (
                      <span
                        key={`variation-${index}-${variation.name}`}
                        className="text-sm bg-yellow-100 text-yellow-500 px-2 py-1 mr-2 mb-2 rounded-lg"
                      >
                        {variation.name}: {variation.option}
                      </span>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );

  return <Modal title={`${recipient.recipient_name}'s Gifts`} onClose={onClose} body={body} />;
}
