import Preview from "components/Preview/Preview";

export default function OrderMessage({ order }) {
  let nameRegex = /{{\s?name\s?}}/g;
  let companyRegex = /{{\s?company\s?}}/g;
  let defaultTitle = {
    id: `Kamu Mendapatkan Gift dari ${order.client_name}`,
    en: `You Got a Gift from ${order.client_name}`,
  };
  let defaultBody = {
    id: `Hi {{ name }},\n${order.client_name} mengirimkan kamu gift. Silahkan konfirmasi alamat untuk menerima.\nP.S: Untuk memudahkan proses pengiriman gift, mohon konfirmasi maksimal 2 hari sejak pesan ini diterima.\nTerima Kasih.`,
    en: `Hi {{ name }},\n${order.client_name} is sending you a gift. Please confirm your address to claim the gift.\nP.S: To smoothen the process of sending you the gifts, please confirm to us max. 2 days after you received this message.\nThank You.`,
  };
  let defaultAction = {
    id: "Klaim",
    en: "Claim",
  };
  let compile = (text) => {
    return text
      .replaceAll(nameRegex, "John")
      .replaceAll(companyRegex, order.client_name)
      .replaceAll("\n", "<br />");
  };

  return (
    <>
      <div className="w-1/2 mx-auto mt-10">
        <Preview
          data={{
            image: order.notification_image_url,
            title: compile(
              order.notification_title || defaultTitle[order.notification_lang]
            ),
            body: compile(
              order.notification_body || defaultBody[order.notification_lang]
            ),
            action:
              order.notification_action ||
              defaultAction[order.notification_lang],
          }}
        />
      </div>
    </>
  );
}
