import imageCompression from "browser-image-compression"

const compress = async file => {
    const compressionOtions = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 728,
        useWebWorker: true,
    }

    return await imageCompression(file, compressionOtions)
}

export default compress