import React, { useEffect, useState } from "react";
import api from "lib/api";
import Spinner from "components/Spinner";
import { useError } from "contexts/error";
import Preview from "components/Preview/Preview";
import ModalOrderRecipientsList from "./ModalOrderRecipientsList";
import Modal from "components/Modal/Modal";

const OrderPreviewRecipients = ({ order, setStep, loadOrder }) => {
  const [products, setProducts] = useState([]);
  const [recipients, setRecipients] = useState([]);

  const [loading, setLoading] = useState(false);
  const { handleError } = useError();

  const [showModal, setShowModal] = useState(false);
  const [showRecipientsModal, setShowRecipientsModal] = useState(false);

  let getProducts = async () => {
    const { data, err } = await api.controllers.getOrderProducts(order.id);
    if (!err) {
      setProducts(data.data);
    }
  };
  const giftProducts = products.filter((product) => product.product_category_type === "gift");
  const packagingProducts = products.filter((product) => product.product_category_type === "packaging");
  const decorationProducts = products.filter((product) => product.product_category_type === "decoration");

  let getRecipients = async () => {
    const { data, err } = await api.controllers.getOrderRecipients(order.id, {
      page: 1,
      limit: -1,
    });
    if (!err) {
      let _recipients = data.data;
      setRecipients(_recipients);
    }
  };

  useEffect(() => {
    loadOrder();
    getProducts();
    getRecipients();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  let onSubmit = async () => {
    setLoading(true);
    const { err } = await api.controllers.publishOrder(order.id);
    setLoading(false);

    if (err) {
      handleError(err);
    } else {
      setStep("published");
    }
  };

  let modal = (
    <div className="text-center flex flex-col items-center pt-20 pb-6">
      <img
        src={require("assets/img/order-edit/publish-confirm.png").default}
        alt={"publish-confirm"}
        className="w-32 mb-7"
      />

      <h2 className="text-2xl font-bold mb-3">Confirm Publish</h2>

      <p className="mb-7">
        Please confirm that you want to publish. Once confirmed, we will send notifications to recipients to receive the
        gift. This action cannot be undo.
      </p>

      <button className="flex bg-primary text-white font-bold px-16 py-3 rounded-lg select-none" onClick={onSubmit}>
        {loading && <Spinner size={4} />} Confirm
      </button>
    </div>
  );

  let renderProducts = (_products) => {
    return _products.map((product) => {
      let imgUrl =
        process.env.REACT_APP_ENVIRONMENT === "production" ? product.product_image_url : "https://picsum.photos/200";
      return (
        <div key={product.id} className="mb-4 flex justify-between">
          <div className="flex pr-4">
            <img src={imgUrl} alt="gambar" className="w-12 h-12 rounded-lg mr-4" />
            <div className="flex items-center">
              <h4 className="font-medium mb-1">{product.product_name}</h4>
            </div>
          </div>
        </div>
      );
    });
  };

  let nameRegex = /{{\s?name\s?}}/g;
  let companyRegex = /{{\s?company\s?}}/g;
  let defaultTitle = {
    id: `Kamu Mendapatkan Gift dari ${order.client_name}`,
    en: `You Got a Gift from ${order.client_name}`,
  };
  let defaultBody = {
    id: `Hi {{ name }},\n${order.client_name} mengirimkan kamu gift. Silahkan konfirmasi alamat untuk menerima.\nP.S: Untuk memudahkan proses pengiriman gift, mohon konfirmasi maksimal 2 hari sejak pesan ini diterima.\nTerima Kasih.`,
    en: `Hi {{ name }},\n${order.client_name} is sending you a gift. Please confirm your address to claim the gift.\nP.S: To smoothen the process of sending you the gifts, please confirm to us max. 2 days after you received this message.\nThank You.`,
  };
  let defaultAction = {
    id: "Klaim",
    en: "Claim",
  };
  let compile = (text) => {
    return text.replaceAll(nameRegex, "John").replaceAll(companyRegex, order.client_name).replaceAll("\n", "<br />");
  };

  return (
    <>
      {showRecipientsModal && (
        <Modal
          hideHeader={true}
          title={``}
          widthClass="min-w-[90%]"
          onClose={() => setShowRecipientsModal((prev) => !prev)}
          body={<ModalOrderRecipientsList order={order} onClose={() => setShowRecipientsModal((prev) => !prev)} />}
        />
      )}

      <div className="bg-white px-5 pt-5 pb-10 rounded-lg border border-tertiary_gray_two mt-10">
        <div className="border-b border-tertiary_gray_two pb-6 mb-10">
          <h3 className="text-2xl font-bold text-center">Preview</h3>
        </div>

        <div className="grid grid-cols-2 gap-x-5">
          <>
            <div className="">
              <div className={`p-4 border border-tertiary_gray_two mb-5 w-full rounded-lg`}>
                <div className="flex mb-3">
                  <p className="w-4/12 font-medium text-lg">Campaign</p>
                  <p className="w-8/12 text-lg">{order.name}</p>
                </div>

                {!order.recurring && (
                  <div className="flex mb-3">
                    <p className="w-4/12 font-medium text-lg">Recipients</p>
                    <div className="w-8/12 flex flex-wrap">
                      {recipients
                        .map((recipient, index) => {
                          return (
                            <p className="mr-2 mb-3 text-lg" key={index}>
                              {recipient.recipient_email ? recipient.recipient_email : recipient.recipient_phone_number}
                            </p>
                          );
                        })
                        .slice(0, 3)}
                      {recipients.length > 3 && (
                        <p
                          onClick={() => setShowRecipientsModal((prev) => !prev)}
                          className="underline text-primary cursor-pointer font-medium text-lg"
                        >
                          {`...${recipients.length - 3} Others`}
                        </p>
                      )}
                    </div>
                  </div>
                )}

                {order.recurring && (
                  <div className="flex mb-3">
                    <p className="w-4/12 font-medium text-lg">Campaign Type</p>
                    <p className="w-8/12 text-lg">{order.type === "branded" ? "Branded Gift" : "Personal Gift"}</p>
                  </div>
                )}

                {order.recurring && (
                  <div className="flex mb-3">
                    <p className="w-4/12 font-medium text-lg">Recurring</p>
                    <p className="w-8/12 text-lg">Yes</p>
                  </div>
                )}

                {order.personal_type === "budget" && (
                  <div className={`${order.recurring ? "" : "mb-3"} flex`}>
                    <p className="w-4/12 font-medium text-lg">Budget</p>
                    <p className="w-8/12 text-lg">
                      {new Intl.NumberFormat("id-ID", {
                        style: "currency",
                        currency: "IDR",
                        maximumFractionDigits: 0,
                      }).format(order.budget)}
                    </p>
                  </div>
                )}
              </div>

              {/* Items  */}
              <div className="w-full rounded-lg p-4 border border-tertiary_gray_two">
                <h2 className="font-bold mb-4 text-xl">Items</h2>

                <div className={`${order.type === "personal" ? "" : "mb-5"}`}>
                  {order.type === "personal" && <h3 className="text-primary font-bold mb-2">Recommendation</h3>}
                  {order.type === "branded" && <h3 className="text-sm text-gray-400 font-bold mb-2">products</h3>}
                  {renderProducts(giftProducts)}
                </div>

                <div className="mb-5">
                  {packagingProducts.length > 0 && (
                    <>
                      <h3 className="text-sm text-gray-400 font-bold mb-2">Packaging</h3>
                      {renderProducts(packagingProducts)}
                    </>
                  )}
                </div>

                {decorationProducts.length > 0 && (
                  <>
                    <h3 className="text-sm text-gray-400 font-bold mb-2">Decorations</h3>
                    {renderProducts(decorationProducts)}
                  </>
                )}
              </div>
            </div>
          </>

          {/* preview  */}
          <div className="">
            <Preview
              data={{
                image: order.notification_image_url,
                title: compile(order.notification_title || defaultTitle[order.notification_lang]),
                body: compile(order.notification_body || defaultBody[order.notification_lang]),
                action: order.notification_action || defaultAction[order.notification_lang],
              }}
            />

            {/* Wrapping  */}
            {order.gift_wrapping !== null && (
              <div className="w-full rounded-lg p-4 border border-tertiary_gray_two mt-5">
                <h2 className="font-bold mb-4 text-xl">Wrapping</h2>
                <div className="bg-white rounded-lg flex items-center gap-x-5">
                  {order.gift_wrapping === "scratch" && (
                    <img
                      src={require("assets/img/order-edit/scratch.gif").default}
                      alt={"scratch"}
                      className="w-4/12 rounded-lg"
                    />
                  )}
                  {order.gift_wrapping === "openbox" && (
                    <img
                      src={require("assets/img/order-edit/openbox.gif").default}
                      alt={"scratch"}
                      className="w-4/12 rounded-lg"
                    />
                  )}
                  <p className="text-lg">
                    {order.gift_wrapping === "scratch" && "Scratch Me"}
                    {order.gift_wrapping === "openbox" && "Snap In The Box"}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* progress button */}
      <div className="flex justify-center items-center gap-x-5 w-fit mx-auto mb-2 mt-8">
        <button
          onClick={() => setStep("message")}
          className="py-3 px-10 w-fit bg-white text-primary font-bold mx-auto flex items-center rounded-lg border border-primary"
        >
          <img
            src={require("assets/img/arrow-right.svg").default}
            alt={"home-no-campaign-icon"}
            className="w-6 mr-2 svg_orange rotate-180"
          />
          <p>Message</p>
        </button>

        <button
          className="py-3 px-10 w-fit bg-primary text-white font-bold mx-auto flex items-center rounded-lg"
          onClick={() => setShowModal(true)}
        >
          <img src={require("assets/img/order-edit/publish.png").default} alt={"publish"} className="w-6 mr-2" />
          <p>Publish</p>
        </button>
      </div>

      {showModal && (
        <Modal hideHeader={true} title={``} widthClass="w-6/12" body={modal} onClose={() => setShowModal(false)} />
      )}
    </>
  );
};

export default OrderPreviewRecipients;
