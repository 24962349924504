import Modal from "components/Modal/Modal";
import Spinner from "components/Spinner";
import api from "lib/api";
import mixpanel from "lib/mixpanel";
import { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import ShowMoreText from "react-show-more-text";

export default function CatalogueProductView({ product, checkRecipientVariation }) {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [more, setMore] = useState(false);
  const [productDetail, setProductDetail] = useState(null);

  const imageUrl = process.env.REACT_APP_ENVIRONMENT === "production" ? product.image_url : "https://picsum.photos/200";

  const onClick = async () => {
    setShowModal(true);
    setLoading(true);
    const { data, err } = await api.controllers.getProduct(product.id);
    setLoading(false);
    if (!err) {
      mixpanel.track("Show Product", {
        Product: product.name,
      });
      setProductDetail(data.data);
    }
  };

  let renderVariation = (variation, index) => {
    return (
      <div className={`mb-2 border-b pb-2`} key={`variation-${variation.id}`}>
        <div className={`text-sm mb-2`}>{variation.name}</div>
        <div className="flex gap-4 w-full flex-wrap">
          {variation.options.map((option) => {
            return (
              <div key={`option-${option.id}`} className={`text-center bg-white p-2 rounded-lg`}>
                <div>{option.name}</div>
                {option.price > 0 && (
                  <div className={`text-xs`}>
                    +{" "}
                    {new Intl.NumberFormat("id-ID", {
                      style: "currency",
                      currency: "IDR",
                      maximumFractionDigits: 0,
                    }).format(option.price)}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const modalBody = productDetail && (
    <div className="p-4">
      {loading && (
        <div className="mx-auto text-center">
          <Spinner color={"black"} center={true} />
        </div>
      )}
      {!loading && (
        <div className="flex gap-4">
          <div className="w-full md:w-1/2">
            <Carousel showIndicators={true} showThumbs={false} autoPlay={true} showArrows={false} showStatus={false}>
              {productDetail.images.map((image) => {
                const imgUrl =
                  process.env.REACT_APP_ENVIRONMENT === "production" ? image.image_url : "https://picsum.photos/200";

                return (
                  <div key={`image-${image.id}`}>
                    <img src={imgUrl} alt={product.name} className="rounded-lg" />
                  </div>
                );
              })}
            </Carousel>
          </div>

          <div className="w-full md:w-1/2">
            <h3 className="text-lg font-bold mb-2">{productDetail.name}</h3>
            {productDetail.prices.length === 0 && (
              <p className="text-primary mb-4 font-bold">
                {new Intl.NumberFormat("id-ID", {
                  style: "currency",
                  currency: "IDR",
                  maximumFractionDigits: 0,
                }).format(productDetail.price)}
              </p>
            )}

            {productDetail.prices.length > 0 && (
              <div className="py-2">
                <div className="flex items-center flex-wrap gap-3">
                  <div className="border-r pr-3">
                    <p className="text-xs text-gray-400 font-medium">
                      1 -
                      {productDetail.prices.sort(
                        (a, b) => productDetail.price - b.discount - (productDetail.price - a.discount)
                      )[0].range_from - 1}
                      pcs
                    </p>
                    <p className="text-primary font-bold">
                      {new Intl.NumberFormat("id-ID", {
                        currency: "IDR",
                        maximumFractionDigits: 0,
                      }).format(productDetail.price)}
                    </p>
                  </div>

                  {productDetail.prices
                    .sort((a, b) => productDetail.price - b.discount - (productDetail.price - a.discount))
                    .map((price, index) => (
                      <div key={`bulk - ${index}`} className="border-r pr-3">
                        <p className="text-xs text-gray-400 font-medium">
                          {" "}
                          {index + 1 === productDetail.prices.length
                            ? `${price.range_from}pcs+`
                            : `${price.range_from} -${price.range_to} pcs`}
                        </p>
                        <p className="text-primary font-bold">
                          {new Intl.NumberFormat("id-ID", {
                            currency: "IDR",
                            maximumFractionDigits: 0,
                          }).format(productDetail.price - price.discount)}
                        </p>
                      </div>
                    ))
                    .slice(0, more ? productDetail.length : 2)}
                </div>

                {productDetail.prices.length > 2 && !more && (
                  <p
                    onClick={() => setMore(true)}
                    className="text-sm text-blue-500 flex items-center cursor-pointer mt-1"
                  >
                    Show more price{" "}
                    <img
                      src={require("assets/img/catalogue/arrow-down-blue.svg").default}
                      alt={"category-icon"}
                      className={`w-3 ml-2 ${more ? "rotate-180" : ""}`}
                    />
                  </p>
                )}
              </div>
            )}
            <p className="text-gray-700 mb-4 whitespace-pre-line">
              <ShowMoreText
                lines={10}
                more={<span className="text-primary">more</span>}
                less={<span className="text-primary">less</span>}
                keepNewLines={true}
              >
                {productDetail.description}
              </ShowMoreText>
            </p>

            <p className="text-gray-500 mb-4">
              *Minimum Order : {product.min_quantity < 20 ? 20 : product.min_quantity} pcs
            </p>

            {productDetail.variations.length > 0 && (
              <div className="p-4 bg-slate-100 rounded-lg">{productDetail.variations.map(renderVariation)}</div>
            )}
          </div>
        </div>
      )}
    </div>
  );

  return (
    <>
      <div
        className="w-full rounded-xl bg-white overflow-hidden cursor-pointer border shadow-lg relative"
        onClick={() => onClick()}
      >
        {product.custom_design && (
          <div
            className="bg-[#F87F00] absolute right-0 top-0 text-white font-bold text-xs 
                px-5 py-2 opacity-70 rounded-tr-xl rounded-bl-xl cursor-pointer"
          >
            Custom
          </div>
        )}
        <img src={imageUrl} alt={product.name} className="w-full object-cover aspect-square" />
        <div className="">
          <h4 className="font-medium line-clamp-2 px-2 pt-2 h-14 mb-2">{product.name}</h4>
          <p className={`font-bold text-sm text-primary px-2 line-clamp-1 h-5 mb-3`}>
            {product.price_lowest > 0 && (
              <>
                <span className="font-medium text-xs">Rp</span>
                {new Intl.NumberFormat("id-ID", {
                  currency: "IDR",
                  maximumFractionDigits: 0,
                }).format(product.price_lowest)}
                <span className="font-bold"> - </span>
              </>
            )}
            <span className="font-medium text-xs">Rp</span>
            {new Intl.NumberFormat("id-ID", {
              currency: "IDR",
              maximumFractionDigits: 0,
            }).format(product.price)}
          </p>
        </div>
      </div>

      {showModal && (
        <Modal
          title={``}
          hideHeader={true}
          body={modalBody}
          onClose={() => {
            setShowModal(false);
            setMore(false);
          }}
        />
      )}
    </>
  );
}
