import Modal from "components/Modal/Modal";
import Spinner from "components/Spinner";
import { useError } from "contexts/error";
import api from "lib/api";
import { useEffect, useState } from "react";

export default function OrderRecipientsTrackModal({ order, onClose, recipient }) {
  const { handleError } = useError();
  const [loading, setLoading] = useState(false);
  const [recipientsTrack, setRecipientsTrack] = useState(null);

  useEffect(() => {
    const getRecipientsStatus = async () => {
      setLoading(true);
      const { data, err } = await api.controllers.getRecipientsStatus(order.id, recipient.id);
      setLoading(false);

      if (err) {
        handleError(err);
      } else {
        setRecipientsTrack(data.data);
      }
    };
    getRecipientsStatus();
  }, [order.id, recipient.id, handleError]);

  const body = (
    <div className="w-full px-5">
      {loading && (
        <div className="flex justify-center">
          <Spinner color="black" />
        </div>
      )}

      {recipient.tracking_courier === "lalamove" && (
        <div>
          <iframe
            title="tracking"
            src={recipient.tracking_number}
            className="w-full h-[55vh] border rounded-md"
          ></iframe>
        </div>
      )}

      {!loading && recipient.tracking_courier !== "lalamove" && (
        <>
          {recipientsTrack && recipientsTrack.length > 0 ? (
            recipientsTrack.reverse().map((track, index) => (
              <div
                key={`track list - ${index}`}
                className={`md:flex mb-2 ${index === 0 ? " font-semibold " : " text-gray-500 "}`}
              >
                <p className="min-w-48 md:text-center">{track.date} &#8594;</p>
                <p className="">{track.status}</p>
              </div>
            ))
          ) : (
            <div className="rounded-md">
              <p className=" text-gray-600 mb-4">
                Tracking otomatis untuk kurir ini sedang tidak tersedia. Silahkan gunakan website kurir dengan detail
                sebagai berikut:
              </p>
              <div className="flex font-bold">
                <div className="mr-4 mb-2">
                  <span>Kurir:</span>
                </div>
                <div>
                  {" "}
                  <span className="capitalize">{recipient.tracking_courier}</span>
                </div>
              </div>
              <div className="flex mb-2 font-bold">
                <div className="mr-4">
                  <span>AWB:</span>
                </div>
                <div>
                  {" "}
                  <p className="w-60 sm:w-96 md:w-60 lg:w-96 break-words">{recipient.tracking_number}</p>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );

  return (
    <Modal
      widthClass={recipient.tracking_courier === "lalamove" ? "min-w-[70%]" : ""}
      title={`Track Order ${recipient.recipient_name}`}
      onClose={onClose}
      body={body}
    />
  );
}
