import React, { useState } from "react";

import Pagination from "components/Pagination";
import Spinner from "components/Spinner";

export default function Grid({ settings, render, loading, data, setPagination, onFilter }) {
  const filters = settings.filters || [];
  const elements = data?.data;
  // const limit = settings.limit || 10;
  const cols = settings.cols || 4;

  const [filterValues, setFilterValues] = useState(
    Object.fromEntries(filters.map((filter) => [filter.field, filter.default || ""]))
  );
  const handleFilterChange = (e) => {
    setFilterValues({ ...filterValues, [e.target.name]: e.target.value });
  };
  const onFilterSubmit = (e) => {
    e.preventDefault();

    onFilter && onFilter(filterValues);
  };

  const onPageChange = (page, limitPerPage) => {
    setPagination({ page: page, limit: limitPerPage ? limitPerPage : 20 });
  };

  return (
    <>
      <div className="rounded-t mb-0 px-3 py-3 border-0">
        <div className="flex flex-wrap items-center">
          <div className="relative w-full ml-2 max-w-full flex-grow flex-1">
            {settings.title && <h3 className={"font-semibold text-lg text-slate-700"}>{settings.title}</h3>}
          </div>
          <div>
            {settings.primaryTwoButton && (
              <>
                <button
                  className="bg-gray-500 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={settings.primaryTwoButton.onClick}
                >
                  {settings.primaryTwoButton.label}
                </button>
              </>
            )}
            {settings.button && (
              <>
                <button
                  className="bg-primary text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md mr-1"
                  type="button"
                  onClick={settings.button.onClick}
                >
                  {settings.button.label}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      {filters.length > 0 && (
        <div className="mb-4 rounded-lg">
          <form className="block w-full overflow-x-auto py-4" onSubmit={onFilterSubmit}>
            <div className="flex gap-x-2 items-center">
              {filters.map((filter) => {
                return (
                  <div className="mb-2 mr-4" key={`filter-${filter.field}`}>
                    {filter.inputType === "select" && (
                      <>
                        <select
                          className="border pl-3 pr-6 py-3 bg-white rounded text-sm shadow"
                          name={filter.field}
                          onChange={handleFilterChange}
                        >
                          <option value="">Choose {filter.label}</option>
                          {filter.options.map((option, index) => (
                            <option value={option.value} key={index}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      </>
                    )}
                    {filter.inputType !== "select" && (
                      <>
                        <input
                          placeholder={filter.label}
                          name={filter.field}
                          type={filter.inputType || "text"}
                          className="border px-3 py-3 bg-white rounded text-sm shadow w-full"
                          value={filterValues[filter.field]}
                          onChange={handleFilterChange}
                        />
                      </>
                    )}
                  </div>
                );
              })}

              <button
                className="bg-primaryTwo text-white font-bold uppercase text px-4 py-2.5 mt-0 rounded shadow"
                style={{
                  marginTop: "-10px",
                }}
                type="submit"
              >
                <i className="fas fa-search" />
              </button>
            </div>
          </form>
        </div>
      )}
      {loading && <Spinner color="black" />}
      {!loading && (
        <div className={`grid grid-cols-${cols} gap-4 mb-4`}>
          {elements && elements.map((element) => <div key={`field-${element.id}`}>{render(element)}</div>)}
        </div>
      )}

      <Pagination
        pageCount={data.total_page}
        itemCount={data.total_items}
        onPageChange={onPageChange}
        showAll={false}
      />
    </>
  );
}
