import React, { useState } from "react";

// components

import Pagination from "components/Pagination";

export default function Table({ settings, data, setPagination, onFilter }) {
  const headers = settings.headers;
  const filters = settings.filters || [];
  const elements = data?.data;

  const [filterValues, setFilterValues] = useState(
    Object.fromEntries(filters.map((filter) => [filter.field, filter.default || ""]))
  );
  const handleFilterChange = (e) => {
    setFilterValues({ ...filterValues, [e.target.name]: e.target.value });
  };
  const onFilterSubmit = (e) => {
    e.preventDefault();

    onFilter && onFilter(filterValues);
  };

  const onPageChange = (page, limitPerPage) => {
    setPagination({ page: page, limit: limitPerPage ? limitPerPage : 20 });
  };

  return (
    <>
      <div className={"relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white"}>
        <div className="rounded-t mb-0 px-3 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full ml-2 max-w-full flex-grow flex-1">
              <h3 className={"font-semibold text-lg text-slate-700"}>{settings.title}</h3>
            </div>
            <div>
              {settings.primaryTwoButton && (
                <>
                  <button
                    className="bg-gray-500 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={settings.primaryTwoButton.onClick}
                  >
                    {settings.primaryTwoButton.label}
                  </button>
                </>
              )}
              {settings.button && (
                <>
                  <button
                    className="bg-primary text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md mr-1"
                    type="button"
                    onClick={settings.button.onClick}
                  >
                    {settings.button.label}
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
        {filters.length > 0 && (
          <>
            <form
              className="block w-full overflow-x-auto py-4 border-t border-gray-100 pl-2 pr-4"
              onSubmit={onFilterSubmit}
            >
              <div className="flex gap-x-4 items-center">
                {filters.map((filter) => {
                  return (
                    <div className="w-full px-4 mb-3" key={`filter-${filter.field}`}>
                      <label className="block uppercase text-slate-600 text-xs font-bold mb-2">{filter.label}</label>
                      <input
                        name={filter.field}
                        type={filter.inputType || "text"}
                        className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value={filterValues[filter.field]}
                        onChange={handleFilterChange}
                      />
                    </div>
                  );
                })}

                <button
                  className="bg-gray-400 text-white font-bold uppercase text px-3 py-2.5 mt-2 rounded shadow"
                  type="submit"
                >
                  <i className="fas fa-search" />
                </button>
              </div>
            </form>
          </>
        )}
        <div className="block w-full overflow-x-auto">
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                {headers.map((header) => (
                  <th
                    key={`header-${header.label}`}
                    className={
                      "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 font-semibold text-left bg-slate-50 text-slate-500 border-slate-100"
                    }
                  >
                    {header.label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {elements &&
                elements.map((element) => (
                  <tr key={`field-${element.id}`}>
                    {headers.map((header, index) => (
                      <td
                        key={`${element.id}-header-${index}`}
                        className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs p-4"
                      >
                        {header.render && header.render(element)}
                        {header.field && element[header.field]}
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>

          <Pagination
            pageCount={data.total_page}
            itemCount={data.total_items}
            onPageChange={onPageChange}
            showAll={true}
          />
        </div>
      </div>
    </>
  );
}
