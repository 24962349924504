import Spinner from "components/Spinner";
import { useAuth } from "contexts/auth";
import api from "lib/api";
import mixpanel from "lib/mixpanel";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

export default function Login() {
  const { login } = useAuth();
  const [values, setValues] = useState({ email: "", password: "" });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { search } = useLocation();
  const [ref, setRef] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(search);
    setRef(params.get("ref"));

    mixpanel.track("Login", {
      Action: "Show",
    });
  }, [search]);

  let handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  let onSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);
    const { data, err } = await api.controllers.createToken(values.email, values.password);
    setLoading(false);
    if (!err) {
      const { err: meErr, data: userData } = await login(data.data.token);
      if (!meErr) {
        let _data = userData.data;
        mixpanel.identify(_data.id);
        mixpanel.setPeople({
          $name: _data.name,
          $email: _data.email,
          $phone: _data.phone_number,
        });
        mixpanel.track("Login", {
          Action: "Success",
        });

        window.location = "/";
      } else {
        setError(meErr.message);
      }
    } else {
      setError(err.message);
    }
  };

  return (
    <>
      <div className="">
        <div className="grid grid-cols-2">
          <div className="relative flex flex-col justify-between min-w-0 break-words h-screen shadow-lg px-12 pt-20">
            <div className="text-white w-8/12">
              <h1 className="text-5xl font-bold mb-3">Welcome!</h1>
              <p className="text-2xl font-light leading-tight">Start sending smiles today without any hassle.</p>
            </div>

            <img src={require("assets/img/auth/login-icon.png").default} alt={"login-icon"} className="w-[34rem]" />
          </div>

          <div className="relative flex flex-col justify-center min-w-0 break-words h-screen shadow-lg bg-white">
            <div className="px-28">
              {ref && (
                <p className="font-bold border border-primary text-center text-primary text-sm mb-4 p-2">
                  Please check your email for account verification
                </p>
              )}

              <p className="text-primaryTwo text-3xl text-center mb-24 font-bold">Sign In</p>

              <form onSubmit={onSubmit}>
                <div className="relative w-full mb-3">
                  <img
                    src={require("assets/img/auth/auth-email.svg").default}
                    alt={"auth-email"}
                    className="w-6 absolute left-3 top-3"
                  />
                  <input
                    name="email"
                    type="email"
                    className="border-0 px-12 py-3 placeholder-slate-300 text-slate-600 bg-gray-100 rounded-lg focus:outline-none focus:ring-1 w-full ease-linear transition-all duration-150"
                    placeholder="Email"
                    value={values.email}
                    onChange={handleChange}
                  />
                </div>

                <div className="relative w-full mb-3">
                  <img
                    src={require("assets/img/auth/auth-password.svg").default}
                    alt={"auth-password"}
                    className="w-6 absolute left-3 top-3"
                  />
                  <input
                    name="password"
                    type={showPassword ? "text" : "password"}
                    className="border-0 px-12 py-3 placeholder-slate-300 text-slate-600 bg-gray-100 rounded-lg focus:outline-none focus:ring-1 w-full ease-linear transition-all duration-150"
                    placeholder="Password"
                    value={values.password}
                    onChange={handleChange}
                  />
                  {showPassword && (
                    <img
                      onClick={() => setShowPassword((prev) => !prev)}
                      src={require("assets/img/order-edit/eyes-open.svg").default}
                      alt={"auth-eye"}
                      className="w-6 absolute right-3 top-3 cursor-pointer"
                    />
                  )}
                  {!showPassword && (
                    <img
                      onClick={() => setShowPassword((prev) => !prev)}
                      src={require("assets/img/order-edit/eyes-close.svg").default}
                      alt={"auth-eye"}
                      className="w-6 absolute right-3 top-3 cursor-pointer"
                    />
                  )}
                </div>

                {error && <p className="text-center text-red-700 font-medium">{error}</p>}
                <div className="flex justify-end mt-2 relative">
                  <div className="">
                    <Link to="/auth/reset-password" className="">
                      <p>Forgot Password?</p>
                    </Link>
                  </div>
                </div>

                <div className="text-center mt-10">
                  <button
                    className="flex justify-center bg-primary text-white text-sm font-bold px-6 py-3 rounded-lg shadow mr-1 mb-1 w-full"
                    type="submit"
                  >
                    {loading && <Spinner />}
                    Sign In
                  </button>
                </div>
              </form>

              <div className="flex justify-center mt-2 relative underline">
                <Link to="/auth/register" className="py-3 ">
                  <p>Create new account</p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
