import Spinner from "components/Spinner";
import { useError } from "contexts/error";
import api from "lib/api";
import React, { useEffect, useState } from "react";

export default function IntegrationTalenta() {
  const [values, setValues] = useState({
    username: "",
    secret: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [integration, setIntegration] = useState({ active: true });
  const [loading, setLoading] = useState(false);
  const [loadingIntegration, setLoadingIntegration] = useState(false);
  const { handleError } = useError();

  const get = async () => {
    setLoadingIntegration(true);
    const { err, data } = await api.controllers.getIntegration("talenta");
    setLoadingIntegration(false);
    if (!err) {
      setIntegration(data.data);
    }
  };

  useEffect(() => {
    get();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const { err } = await api.controllers.integrationAuthTalenta(values);
    // setIntegration((prev) => ({ ...prev, active: true }));
    setLoading(false);

    if (err) {
      handleError(err);
    } else {
      get();
    }
  };

  return (
    <>
      <div className="w-full mb-6 shadow-lg rounded-lg border-0 p-6 bg-white">
        <div className="mb-12">
          <img
            src={require(`assets/img/talenta.png`).default}
            alt={"talenta-logo"}
            className={`w-28 h-28 mx-auto object-contain m-6`}
          />
          {loadingIntegration ? (
            <div className="w-fit mx-auto">
              <Spinner color={"black"} />
            </div>
          ) : (
            <p
              className={`text-xs font-medium py-1 px-2 w-fit mx-auto rounded-full ${
                integration.active ? "text-green-600 bg-green-100" : "text-red-600 bg-red-100"
              }`}
            >
              <i className={`fas ${integration.active ? "fa-check-circle" : "fa-times-circle"} mr-1`} />{" "}
              {integration.active ? "Integration Enabled" : "Not Active"}
            </p>
          )}
        </div>

        {!loadingIntegration && (
          <>
            <div className="px-5 py-5 bg-[#F0F5FA] grid grid-cols-2">
              <div>
                <h6 className="text-slate-700 text-xl font-bold mb-4">Talenta Integration</h6>
                <p className="text-base mb-2">
                  With Talenta integration, Yippy will be able to import and synchronize your employees data. Only the
                  following data will be imported:
                </p>

                <ul className="pl-4 mb-4">
                  <li className="list-disc">Name</li>
                  <li className="list-disc">Email</li>
                  <li className="list-disc">Phone Number</li>
                  <li className="list-disc">Birth date</li>
                  <li className="list-disc">Gender</li>
                  <li className="list-disc">Address</li>
                  <li className="list-disc">Marital Status</li>
                  <li className="list-disc">Organization Name</li>
                  <li className="list-disc">Join Date</li>
                </ul>
              </div>

              <div className={`${!integration.active ? "bg-white" : ""} p-8 h-fit`}>
                <>
                  {!integration.active && (
                    <>
                      <form onSubmit={onSubmit}>
                        <h6 className="text-xl mb-8 font-bold text-center">Account Information</h6>
                        <div className="w-full">
                          <div className="relative w-full mb-3">
                            <label className="block text-slate-600 text-sm font-semibold mb-2">
                              <i className={`fas fa-user mr-3`} />
                              Client ID
                              <span className="text-red-500">
                                <sup>*</sup>
                              </span>
                            </label>
                            <input
                              name="username"
                              type="text"
                              className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              placeholder="Budi"
                              required={true}
                              value={values.username}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="w-full">
                          <div className="relative w-full mb-3">
                            <label className="block text-slate-600 text-sm font-semibold mb-2">
                              <i className={`fas fa-key mr-3`} />
                              Client Secret
                              <span className="text-red-500">
                                <sup>*</sup>
                              </span>
                            </label>
                            <input
                              name="secret"
                              type={showPassword ? "text" : "password"}
                              className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              placeholder="*****"
                              value={values.secret}
                              onChange={handleChange}
                            />
                            {showPassword && (
                              <img
                                onClick={() => setShowPassword((prev) => !prev)}
                                src={require("assets/img/order-edit/eyes-open.svg").default}
                                alt={"auth-eye"}
                                className="w-6 absolute right-3 bottom-2 cursor-pointer"
                              />
                            )}
                            {!showPassword && (
                              <img
                                onClick={() => setShowPassword((prev) => !prev)}
                                src={require("assets/img/order-edit/eyes-close.svg").default}
                                alt={"auth-eye"}
                                className="w-6 absolute right-3 bottom-2 cursor-pointer"
                              />
                            )}
                          </div>
                        </div>

                        <button
                          className="flex bg-primary text-white font-bold uppercase text-xs px-4 py-2 rounded shadow mr-1"
                          type="submit"
                        >
                          {loading && <Spinner size={4} />} Save
                        </button>
                      </form>

                      {loading && (
                        <>
                          <p className="text-xs text-gray-400">
                            Please wait for a few seconds while we are syncing the data...
                          </p>
                        </>
                      )}
                    </>
                  )}
                </>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
