import Spinner from "components/Spinner";
import api from "lib/api";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router";

export default function Verify() {
  const { token } = useParams();
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    const verify = async () => {
      const { err } = await api.controllers.verify(token);
      setLoading(false);

      if (!err) {
        setSuccess(true);
      }
    };
    verify();
  }, [token]);

  return (
    <>
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-slate-200 border-0">
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0 mt-10">
                <div className="text-slate-400 text-center mb-3 font-bold">
                  <small>Verifikasi Akun</small>
                </div>

                <div className="mt-8 text-center">
                  {loading && (
                    <div className="flex justify-center">
                      <Spinner />
                    </div>
                  )}

                  {!loading && (
                    <>
                      {success && (
                        <>
                          <p>Verifikasi akun berhasil. Silahkan login menggunakan akun yang telah terverifikasi.</p>

                          <Link
                            to="/auth/login"
                            className="block mt-4 bg-slate-800 text-white active:bg-slate-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                          >
                            Login
                          </Link>
                        </>
                      )}
                      {!success && (
                        <>
                          <p>Verifikasi akun tidak berhasil.</p>

                          <Link
                            to="/auth/login"
                            className="block mt-4 bg-slate-800 text-white active:bg-slate-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                          >
                            Kembali ke Login
                          </Link>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
