import Spinner from "components/Spinner";
import api from "lib/api";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router";

export default function VerifyPassword() {
  const { token } = useParams();
  const [values, setValues] = useState({ password: "" });

  const [verifyLoading, setVerifyLoading] = useState(true);
  const [loading, setLoading] = useState(false);

  const [verifySuccess, setVerifySuccess] = useState(false);
  const [success, setSuccess] = useState(false);

  const [verifyError, setVerifyError] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const verify = async () => {
      const { err } = await api.controllers.verifyResetPassword(token);
      setVerifyLoading(false);

      if (!err) {
        setVerifySuccess(true);
      } else {
        setVerifyError(err.message);
      }
    };
    verify();
  }, [token]);

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);
    const { err } = await api.controllers.resetPassword(token, values.password);
    setLoading(false);
    if (!err) {
      setSuccess(true);
    } else {
      setError(err.message);
    }
  };

  let passswordForm = (
    <form onSubmit={onSubmit}>
      <div className="relative w-full mb-3">
        <label className="block uppercase text-slate-600 text-xs font-bold mb-2 text-left">
          New Password{" "}
          <span className="text-red-500">
            <sup>*</sup>
          </span>
        </label>
        <input
          name="password"
          type="password"
          className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          placeholder="Password"
          value={values.password}
          onChange={handleChange}
        />
      </div>

      {error && <p className="text-center text-red-700 text-sm">{error}</p>}

      <div className="text-center mt-4">
        <button
          className="bg-primary flex justify-center text-white text-sm font-bold uppercase px-6 py-3 rounded shadow mr-1 mb-1 w-full"
          type="submit"
        >
          {loading && <Spinner />}
          Reset
        </button>
      </div>
    </form>
  );

  return (
    <>
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-slate-200 border-0">
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0 mt-10">
                <div className="text-slate-400 text-center mb-3 font-bold">
                  <small>Reset Password</small>
                </div>

                <div className="mt-8 text-center">
                  {verifyLoading && (
                    <div className="flex justify-center">
                      <Spinner />
                    </div>
                  )}

                  {!verifyLoading && (
                    <>
                      {verifySuccess && (
                        <>
                          {!success && <>{passswordForm}</>}

                          {success && (
                            <>
                              <p>Reset password berhasil. Silahkan login menggunakan password baru.</p>

                              <Link
                                to="/auth/login"
                                className="block mt-4 bg-slate-800 text-white active:bg-slate-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                              >
                                Login
                              </Link>
                            </>
                          )}
                        </>
                      )}
                      {!verifySuccess && (
                        <>
                          <p>{verifyError}</p>

                          <Link
                            to="/auth/login"
                            className="block mt-4 bg-slate-800 text-white active:bg-slate-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                          >
                            Kembali ke Login
                          </Link>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
