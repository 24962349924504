import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import api from "lib/api";
import Spinner from "components/Spinner";
import mixpanel from "lib/mixpanel";
import { useLocation } from "react-router";
import queryString from "query-string";

export default function Register() {
  const [values, setValues] = useState({
    client: "",
    name: "",
    email: "",
    phone_number: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  let query = useLocation();
  const queryObject = queryString.parse(query.search);

  useEffect(() => {
    mixpanel.track("Register", {
      Action: "Show",
      ...(queryObject.referrer ? { $referrer: queryObject.referrer } : {}),
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  let handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  let onSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);
    const { err } = await api.controllers.register(values);
    setLoading(false);
    if (!err) {
      mixpanel.track("Register", {
        Action: "Success",
        ...(queryObject.referrer ? { $referrer: queryObject.referrer } : {}),
      });

      window.location = "/auth/login?ref=register-success";
    } else {
      setError(err.message);
    }
  };

  return (
    <>
      <div className="grid grid-cols-2">
        <div className="relative flex flex-col justify-between min-w-0 break-words h-screen shadow-lg px-12 pt-10">
          <>
            <div className="w-1/2 text-right mb-8">
              <Link to="/auth/login" className="text-slate-200">
                <img
                  src={require("assets/img/arrow-right.svg").default}
                  alt={"arrow-right"}
                  className="w-8 rotate-180"
                />
              </Link>
            </div>

            <div className="text-white w-10/12">
              <h1 className="text-5xl font-bold mb-3">Start your gifting journey with us.</h1>
            </div>
          </>

          <img src={require("assets/img/auth/sign-up-icon.png").default} alt={"sign-up-icon"} className="w-[30rem]" />
        </div>

        <div className="relative flex flex-col justify-center min-w-0 break-words h-screen shadow-lg bg-white">
          <div className="px-28">
            <p className="text-primaryTwo text-3xl text-center mb-10 font-bold">Sign Up</p>

            <form onSubmit={onSubmit}>
              <div className="relative w-full mb-5">
                <img
                  src={require("assets/img/auth/auth-company.svg").default}
                  alt={"auth-company"}
                  className="w-6 absolute left-3 top-3"
                />
                <input
                  name="client"
                  type="text"
                  placeholder="Company"
                  value={values.client}
                  onChange={handleChange}
                  className="border-0 px-12 py-3 placeholder-slate-300 text-slate-600 bg-gray-100 rounded-lg focus:outline-none focus:ring-1 w-full ease-linear transition-all duration-150"
                />
              </div>

              <div className="relative w-full mb-5">
                <img
                  src={require("assets/img/auth/auth-user.svg").default}
                  alt={"auth-user"}
                  className="w-6 absolute left-3 top-3"
                />
                <input
                  name="name"
                  type="text"
                  placeholder="Name"
                  value={values.name}
                  onChange={handleChange}
                  className="border-0 px-12 py-3 placeholder-slate-300 text-slate-600 bg-gray-100 rounded-lg focus:outline-none focus:ring-1 w-full ease-linear transition-all duration-150"
                />
              </div>

              <div className="relative w-full mb-5">
                <img
                  src={require("assets/img/auth/auth-email.svg").default}
                  alt={"auth-email"}
                  className="w-6 absolute left-3 top-3"
                />
                <input
                  name="email"
                  type="email"
                  placeholder="Company email"
                  value={values.email}
                  onChange={handleChange}
                  className="border-0 px-12 py-3 placeholder-slate-300 text-slate-600 bg-gray-100 rounded-lg focus:outline-none focus:ring-1 w-full ease-linear transition-all duration-150"
                />
              </div>

              <div className="relative w-full mb-5">
                <img
                  src={require("assets/img/auth/auth-phone.svg").default}
                  alt={"auth-phone"}
                  className="w-6 absolute left-3 top-3"
                />
                <input
                  name="phone_number"
                  type="tel"
                  placeholder="0812xxx"
                  value={values.phone_number}
                  onChange={handleChange}
                  className="border-0 px-12 py-3 placeholder-slate-300 text-slate-600 bg-gray-100 rounded-lg focus:outline-none focus:ring-1 w-full ease-linear transition-all duration-150"
                />
              </div>

              <div className="relative w-full mb-5">
                <img
                  src={require("assets/img/auth/auth-password.svg").default}
                  alt={"auth-password"}
                  className="w-6 absolute left-3 top-3"
                />
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  placeholder="Password"
                  value={values.password}
                  onChange={handleChange}
                  className="border-0 px-12 py-3 placeholder-slate-300 text-slate-600 bg-gray-100 rounded-lg focus:outline-none focus:ring-1 w-full ease-linear transition-all duration-150"
                />
                <img
                  onClick={() => setShowPassword((prev) => !prev)}
                  src={require("assets/img/auth/auth-eye.svg").default}
                  alt={"auth-eye"}
                  className="w-6 absolute right-3 top-3 cursor-pointer"
                />
              </div>

              {error && <p className="text-center text-red-700 font-bold">{error}</p>}

              <div className="text-center mt-6">
                <button
                  className="flex justify-center bg-primary text-white text-sm font-bold px-6 py-3 rounded-lg shadow mr-1 mb-1 w-full"
                  type="submit"
                >
                  {loading && <Spinner />}
                  Sign Up
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
