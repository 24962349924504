import api from "lib/api";
import React, { useEffect, useState } from "react";
import CatalogueProductView from "components/Catalogue/CatalogueProductView";

function OrderRecommended() {
  const [data, setData] = useState([]);

  let getProducts = async () => {
    const { data, err } = await api.controllers.getProducts({ limit: 10 });
    if (!err) {
      setData(data.data);
    }
  };
  useEffect(() => {
    getProducts();
  }, []);

  return (
    <div className="grid grid-cols-2 lg:grid-cols-5 gap-2">
      {data.map((item) => {
        return (
          <div
            key={item.id}
            className={`mx-auto w-full rounded-xl border bg-white relative`}
          >
            <CatalogueProductView product={item} imgStyle="w-full h-40" />
          </div>
        );
      })}
    </div>
  );
}

export default OrderRecommended;
