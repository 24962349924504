import React, { useEffect } from "react";

import Catalogue from "components/Catalogue/Catalogue";
import CatalogueProductView from "components/Catalogue/CatalogueProductView";
import mixpanel from "lib/mixpanel";

export default function ProductList() {
  useEffect(() => {
    mixpanel.track("Catalogue");
  }, []);

  const renderProduct = (product) => {
    return <CatalogueProductView key={`${product.id}`} product={product} checkRecipientVariation={true} />;
  };

  return (
    <>
      <div className="">
        <div className="w-full">
          <Catalogue
            renderProduct={renderProduct}
            options={{
              categoryType: "all",
              contentBorder: true,
              cols: 5,
              showAllCategories: true,
              showKeyword: false,
              showSort: true,
              showSearch: true,
              showFilters: true,
            }}
          />
        </div>
      </div>
    </>
  );
}
