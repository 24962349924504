import { useError } from "contexts/error";
import React from "react";

function FormName({ values, handleChange, setFormBody }) {
  const { handleError } = useError();

  return (
    <div className="w-10/12 mx-auto mb-3 relative flex flex-col items-center ">
      <img
        src={require("assets/img/new-order/new-order-name-icon.png").default}
        alt={"new-order-name-icon"}
        className="w-36 mx-auto mt-10 mb-5"
      />
      <label className="block font-bold text-center text-3xl">
        How would you like to name this campaign?
      </label>
      <input
        name="name"
        type="text"
        className="text-center px-3 py-3 my-8 placeholder-slate-300 rounded bg-white border-b-2 border-0 border-blue-800
		 focus:ring-0 w-10/12 ease-linear transition-all duration-150"
        placeholder="Input Campaign Name"
        value={values.name}
        onChange={handleChange}
      />
      <div
        className="py-3 px-16 w-fit bg-primary text-white font-bold cursor-pointer mx-auto flex items-center rounded-lg"
        onClick={() => {
          values.name.length > 0
            ? setFormBody("type")
            : handleError({ message: "name field can't be empty" });
        }}
      >
        <p>Next</p>
        <img
          src={require("assets/img/arrow-right.svg").default}
          alt={"arrow-right"}
          className="w-4 h-4 ml-2 "
        />
      </div>
    </div>
  );
}

export default FormName;
