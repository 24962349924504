import React, { useCallback, useEffect, useState } from "react";
import api from "lib/api";
import CatalogueProductForm from "components/Catalogue/CatalogueProductForm";
import { useError } from "contexts/error";
import mixpanel from "lib/mixpanel";
import Catalogue from "components/Catalogue/Catalogue";
import Modal from "components/Modal/Modal";

const OrderBrandedItems = ({ order, step, setStep }) => {
  const [products, setProducts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [setmodalBody, setSetModalBody] = useState("items");

  const { handleError } = useError();

  const fetchOrderProducts = useCallback(async () => {
    const { data, err } = await api.controllers.getOrderProducts(order.id);
    if (!err) {
      setProducts(data.data);
    }
  }, [order.id]);

  // const getProducts = async () => {
  //   const { data, err } = await api.controllers.getOrderProducts(order.id);
  //   if (!err) {
  //     setProducts(data.data);
  //   }
  // };

  useEffect(() => {
    fetchOrderProducts();
  }, [fetchOrderProducts]);

  const giftProducts = products.filter((item) => item.product_category_type === "gift");
  const packagingProducts = products.filter((item) => item.product_category_type === "packaging");
  const decorationProducts = products.filter((item) => item.product_category_type === "decoration");

  useEffect(() => {
    giftProducts.length <= 0 && setStep("item");
  }, [giftProducts, setStep]);

  useEffect(() => {
    step === "decoration" && giftProducts.length > 1 && packagingProducts.length <= 0 && setStep("packaging");
  }, [packagingProducts.length, giftProducts.length, step, setStep]);

  const onDelete = async (id) => {
    const { err } = await api.controllers.deleteProduct(order.id, id);
    if (!err) {
      fetchOrderProducts();
    }
  };

  let onProductAdd = async (product, values) => {
    const { err } = await api.controllers.addOrderProduct(order.id, {
      products: [
        {
          id: product.id,
          quantity: values.quantity,
          note: values.note,
          variations: values.variations,
        },
      ],
    });

    if (err) {
      handleError(err);
    } else {
      mixpanel.track("Create Campaign", {
        Action: "Add Product",
        Campaign: order.name,
        Type: order.type,
        Product: product.name,
      });
      fetchOrderProducts();

      return true;
    }

    return false;
  };

  let renderProduct = (product) => {
    return (
      <CatalogueProductForm
        key={product.id}
        product={product}
        addedItem={products}
        onDelete={onDelete}
        onSubmit={onProductAdd}
        checkRecipientVariation={true}
      />
    );
  };

  let renderPreviewProducts = (_products) => {
    return _products.map((product) => {
      let imgUrl =
        process.env.REACT_APP_ENVIRONMENT === "production" ? product.product_image_url : "https://picsum.photos/200";

      return (
        <div key={product.id} className="px-3 flex items-center justify-between">
          <div className="py-3 min-w-max">
            <img src={imgUrl} alt={product.product_name} className="w-16 h-16 rounded-lg mr-3" />
          </div>

          <div className={`py-3 w-11/12 mr-3 px-3 ${product.variations.length > 0 ? "" : "flex items-center"}`}>
            <h4 className="text-sm mb-1"> {product.product_name}</h4>
            {order.type === "branded" && (
              <div className="">
                {product.variations &&
                  product.variations.map((variation, index) => (
                    <span
                      key={`variation-${index}-${variation.name}`}
                      className="text-sm w-fit bg-secondaryTwo px-2 py-1 mr-2 mb-1 rounded-lg"
                    >
                      {variation.option}
                    </span>
                  ))}
              </div>
            )}
          </div>

          <p className="text-sm py-3 w-3/12 mr-3 px-3">
            {new Intl.NumberFormat("id-ID", {
              style: "currency",
              currency: "IDR",
              maximumFractionDigits: 0,
            }).format(product.price)}
          </p>

          <div className="cursor-pointer pr-8 py-3 font-bold w-2/12" onClick={() => onDelete(product.id)}>
            <img
              src={require("assets/img/order-edit/cart-delete.svg").default}
              alt={"cart-delete"}
              className="w-6 ml-auto"
            />
          </div>
        </div>
      );
    });
  };

  const modalBody = (
    <>
      <div className="w-full mb-4 mt-10 px-10 flex gap-x-5">
        <div
          onClick={() => setSetModalBody("items")}
          className={`font-medium cursor-pointer border-b py-2 px-8 ${
            setmodalBody === "items" ? "border-primaryTwo bg-secondaryTwo" : "text-gray-400 border-gray-400"
          }`}
        >
          Items
        </div>
        <div
          onClick={() => setSetModalBody("packaging")}
          className={`font-medium cursor-pointer border-b py-2 px-8 ${
            setmodalBody === "packaging" ? "border-primaryTwo bg-secondaryTwo" : "text-gray-400 border-gray-400"
          }`}
        >
          Packaging
        </div>
        <div
          onClick={() => setSetModalBody("decorations")}
          className={`font-medium cursor-pointer border-b py-2 px-8 ${
            setmodalBody === "decorations" ? "border-primaryTwo bg-secondaryTwo" : "text-gray-400 border-gray-400"
          }`}
        >
          Decorations
        </div>
      </div>

      <div className="w-full pb-16 px-5 rounded-lg flex">
        <div className="w-full bg-white rounded-lg p-5 mb-2">
          {products.length <= 0 && (
            <>
              <p className="text-lg font-bold text-center">No items chosen yet</p>
            </>
          )}

          {products.length > 0 && giftProducts.length > 0 && (
            <div className="items-center w-full bg-transparent border-collapse rounded-lg overflow-hidden">
              <div className="bg-gray-100 px-3 flex justify-between">
                <p className="text-left py-3 font-bold w-16">Item</p>
                <p className={`text-left py-3 font-bold w-11/12 mr-28`}></p>
                <p className="text-left py-3 font-bold mr-10 w-3/12">Price</p>

                <p className={`text-left py-3 pr-2 font-bold w-2/12 ${order.type !== "branded" ? "-mr-8" : ""}`}>
                  &nbsp;
                </p>
              </div>
              {setmodalBody === "items" && <>{renderPreviewProducts(giftProducts)}</>}
              {setmodalBody === "packaging" && packagingProducts.length > 0 && (
                <>{renderPreviewProducts(packagingProducts)}</>
              )}
              {setmodalBody === "decorations" && decorationProducts.length > 0 && (
                <>{renderPreviewProducts(decorationProducts)}</>
              )}
            </div>
          )}
        </div>

        {/* right part  */}
        <div className="w-6/12 mt-5 px-5 py-3 bg-gray-100 rounded-lg">
          <div className="border-b border-dashed border-primaryTwo pb-7 mb-7">
            <h3 className="font-bold">Price / Package</h3>
            <p className="font-medium">
              {new Intl.NumberFormat("id-ID", {
                style: "currency",
                currency: "IDR",
                maximumFractionDigits: 0,
              }).format(products.reduce((prev, { price }) => prev + price, 0))}
            </p>
          </div>

          <div>
            {step === "item" && (
              <>
                <button
                  className={`flex justify-center text-white font-bold py-2 rounded-md mb-3 cursor-pointer select-none w-full ${
                    giftProducts.length <= 0 ? "bg-tertiary_gray_two" : "bg-primary"
                  }`}
                  onClick={() => {
                    setStep("packaging");
                    setShowModal((prev) => !prev);
                  }}
                  disabled={giftProducts.length <= 0}
                >
                  Choose Packaging
                </button>

                <button
                  className="flex justify-center bg-white text-primary font-bold py-2 rounded-md mb-3 border border-primary cursor-pointer select-none w-full"
                  onClick={() => setShowModal((prev) => !prev)}
                >
                  Add More
                </button>
              </>
            )}

            {step === "packaging" && (
              <>
                <button
                  className={`flex justify-center text-white font-bold py-2 rounded-md mb-3 cursor-pointer select-none w-full ${
                    packagingProducts.length <= 0 ? "bg-tertiary_gray_two" : "bg-primary"
                  }`}
                  onClick={() => {
                    setStep("decoration");
                    setShowModal((prev) => !prev);
                  }}
                  disabled={packagingProducts.length <= 0}
                >
                  Choose Decoration
                </button>

                <button
                  className="flex justify-center bg-white text-primary font-bold py-2 rounded-md mb-3 border border-primary cursor-pointer select-none w-full"
                  onClick={() => {
                    setStep("item");
                    setShowModal((prev) => !prev);
                  }}
                >
                  Back
                </button>
              </>
            )}

            {step === "decoration" && (
              <>
                <button
                  className={`flex justify-center bg-primary text-white font-bold py-2 rounded-md mb-3 cursor-pointer select-none w-full`}
                  onClick={() => setStep("quotation")}
                >
                  Quotation
                </button>

                <button
                  className="flex justify-center bg-white text-primary font-bold py-2 rounded-md mb-3 border border-primary cursor-pointer select-none w-full"
                  onClick={() => {
                    setStep("packaging");
                    setShowModal((prev) => !prev);
                  }}
                >
                  Back
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );

  return (
    <>
      {showModal && (
        <Modal
          hideHeader={true}
          title={``}
          widthClass="min-w-[90%]"
          onClose={() => setShowModal((prev) => !prev)}
          body={modalBody}
        />
      )}

      <div className="w-full py-5 relative">
        <div className="">
          <h2 className="text-2xl font-bold mb-10 text-center">
            {step === "item" && "Add Items Inside The Package"}
            {step === "packaging" && "Choose Packaging"}
            {step === "decoration" && "Add Decoration (Optional)"}
          </h2>

          {step === "item" && (
            <Catalogue
              addedItem={products}
              onToggleModal={() => setShowModal((prev) => !prev)}
              renderProduct={renderProduct}
              options={{
                categoryType: "gift",
                contentBorder: true,
                cols: 5,
                quantity: order.recipient_count,
                showAllCategories: true,
                showSort: true,
                showCart: true,
                showFilters: true,
                showSearch: true,
              }}
            />
          )}
          {step === "packaging" && (
            <Catalogue
              addedItem={products}
              onToggleModal={() => setShowModal((prev) => !prev)}
              renderProduct={renderProduct}
              options={{
                categoryType: "packaging",
                cols: 5,
                showCategories: true,
                showFilters: true,
                contentBorder: true,
                quantity: order.recipient_count,
                showCart: true,
                showSearch: true,
              }}
            />
          )}
          {step === "decoration" && (
            <Catalogue
              addedItem={products}
              onToggleModal={() => setShowModal((prev) => !prev)}
              renderProduct={renderProduct}
              options={{
                categoryType: "decoration",
                cols: 5,
                showFilters: true,
                contentBorder: true,
                quantity: order.recipient_count,
                showCart: true,
                showSearch: true,
              }}
            />
          )}
        </div>
      </div>

      <div className="flex justify-center bg-white fixed bottom-0 right-0 left-0 py-4 border-t border-slate-200">
        {step === "item" && (
          <>
            <button
              className={`${
                giftProducts.length <= 0 ? "bg-tertiary_gray_two" : "bg-primary"
              } w-fit mx-auto text-white font-bold px-10 py-2 rounded-lg shadow cursor-pointer select-none`}
              onClick={() => setStep("packaging")}
              disabled={giftProducts.length <= 0}
            >
              Choose Packaging
            </button>
          </>
        )}

        {step === "packaging" && (
          <div className="w-fit mx-auto flex gap-x-5">
            <button
              className="bg-white text-primary font-bold px-10 py-2 rounded-md border border-primary cursor-pointer select-none w-fit flex"
              onClick={() => setStep("item")}
            >
              <img
                src={require("assets/img/arrow-right.svg").default}
                alt={"arrow-left"}
                className="w-6 mr-2 mx-auto rotate-180 svg_orange"
              />
              <span>back</span>
            </button>

            <button
              className={`${
                packagingProducts.length <= 0 ? "bg-tertiary_gray_two" : "bg-primary"
              }  text-white font-bold px-10 py-2 rounded-md cursor-pointer select-none w-fit flex`}
              onClick={() => setStep("decoration")}
              disabled={packagingProducts.length <= 0}
            >
              <span>Add Decoration</span>
              <img
                src={require("assets/img/arrow-right.svg").default}
                alt={"arrow-right"}
                className="w-6 ml-2 mx-auto"
              />
            </button>
          </div>
        )}

        {step === "decoration" && (
          <div className="w-fit mx-auto flex gap-x-5">
            <button
              className="bg-white text-primary font-bold px-10 py-2 rounded-md border border-primary cursor-pointer select-none w-fit flex"
              onClick={() => setStep("packaging")}
            >
              <img
                src={require("assets/img/arrow-right.svg").default}
                alt={"arrow-left"}
                className="w-6 mr-2 mx-auto rotate-180 svg_orange"
              />
              <span>back</span>
            </button>

            <button
              className="bg-primary text-white font-bold px-10 py-2 rounded-md cursor-pointer select-none w-fit flex"
              onClick={() => setStep("quotation")}
            >
              {decorationProducts.length > 0 ? <span>Proposal</span> : <span>Skip</span>}
              <img
                src={require("assets/img/arrow-right.svg").default}
                alt={"arrow-right"}
                className="w-6 ml-2 mx-auto"
              />
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default OrderBrandedItems;
