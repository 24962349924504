import React, { useCallback, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import Table from "components/Table/Table.js";
import api from "lib/api";
import Spinner from "components/Spinner";
import Modal from "components/Modal/Modal";
import { useError } from "contexts/error";
import mixpanel from "lib/mixpanel";

export default function RecipientList() {
  const [data, setData] = useState({});
  const [pagination, setPagination] = useState(null);
  const history = useHistory();
  const { handleError } = useError();

  const [values, setValues] = useState({ file: null });
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [failedData, setFailedData] = useState([]);
  const [filterValues, setFilterValues] = useState(null);

  useEffect(() => {
    mixpanel.track("List Recipients");
  }, []);

  const fetchRecipients = useCallback(async () => {
    const { data, err } = await api.controllers.getRecipients(pagination.page, pagination.limit, filterValues);
    if (!err) {
      setData(data);
    }
  }, [filterValues, pagination]);

  useEffect(() => {
    pagination && fetchRecipients();
  }, [pagination, fetchRecipients]);

  useEffect(() => {
    filterValues && fetchRecipients();
  }, [filterValues, fetchRecipients]);

  const onFilter = async (_values) => {
    setFilterValues(_values);
  };

  const onFileChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.files });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setFailedData([]);

    setLoading(true);
    const { err, data } = await api.controllers.importRecipient({
      file: values.file[0],
    });
    setLoading(false);

    if (err) {
      handleError(err);
    } else {
      const _failedData = data.data.failed_data;
      if (_failedData.length > 0) {
        setFailedData(_failedData);
      } else {
        setShowModal(false);
        fetchRecipients();
      }
    }
  };

  const form = (
    <>
      <form>
        <div className="w-full lg:w-12/12 px-4">
          <div className="relative w-full mb-3">
            <label className="block uppercase text-slate-600 text-xs font-bold mb-2" htmlFor="grid-password">
              File
            </label>

            <input
              name="file"
              type="file"
              className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              onChange={onFileChange}
              accept=".xls, .xlsx"
            />
          </div>

          {failedData.length > 0 && (
            <ul className="text-red-500 text-sm list-disc pl-4 mb-4">
              {failedData.map((failedData) => (
                <li>
                  {failedData[0].message} at row {failedData[0].index}
                </li>
              ))}
            </ul>
          )}

          <button
            className="flex bg-primary text-white font-bold uppercase text-xs px-4 py-2 rounded shadow mr-1"
            type="submit"
            onClick={(e) => onSubmit(e)}
          >
            {loading && <Spinner size={4} />} Import
          </button>
        </div>
      </form>

      <div className="w-full lg:w-12/12 px-4">
        <div className="relative w-full mt-5">
          <ul className="text-gray-500 text-sm list-disc pl-4">
            <li>
              Please use the following template:
              <a
                className="underline ml-2 font-bold"
                href={"/assets/recipient_template.xlsx"}
                target={"_blank"}
                rel="noreferrer"
              >
                download.
              </a>
            </li>
            <li>Name and Email are required.</li>
            <li>Phone Number, Birthdate, Onboarding date and Groups are not required but recommended.</li>
            <li>Group name is case insensitive. Separate different groups using comma.</li>
          </ul>
        </div>
      </div>
    </>
  );

  const modal = <Modal title={"Import Recipients"} onClose={() => setShowModal(false)} body={form} />;

  return (
    <>
      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          <Table
            settings={{
              title: "Recipients",
              button: {
                label: "Import",
                onClick: () => {
                  setShowModal(true);
                },
              },
              primaryTwoButton: {
                label: "Add",
                onClick: () => {
                  history.push("/recipients/new");
                },
              },
              headers: [
                {
                  label: "Name",
                  render: (element) => {
                    return (
                      <Link to={`/recipients/${element.id}`} className={"underline"}>
                        {element.name}
                      </Link>
                    );
                  },
                },
                {
                  label: "Email",
                  field: "email",
                },
                {
                  label: "Phone Number",
                  field: "phone_number",
                },
                {
                  label: "Address",
                  field: "default_address",
                },
                {
                  label: "Status",
                  render: (element) => {
                    return element.active ? "Active" : "Not Active";
                  },
                },
              ],
              filters: [
                {
                  label: "Name",
                  field: "name",
                },
                {
                  label: "Email",
                  field: "email",
                  inputType: "email",
                },
                {
                  label: "Phone Number",
                  field: "phone_number",
                  inputType: "tel",
                },
              ],
            }}
            data={data}
            setPagination={setPagination}
            onFilter={onFilter}
          />
        </div>
      </div>

      {showModal ? modal : null}
    </>
  );
}
