import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useError } from "contexts/error";
import api from "lib/api";
import mixpanel from "lib/mixpanel";
import FormName from "./OrderNew/FormName";
import FormType from "./OrderNew/FormType";
import FormDelivery from "./OrderNew/FormDelivery";
import Modal from "components/Modal/Modal";

export default function OrderNew({ onClose, campaignName }) {
  const [values, setValues] = useState({
    name: campaignName,
    recurring: false,
    type: "",
    recipient_count: 0,
    deliver_to: "",
    delivery_date: "",
    recurring_until: "",
    request_address: true,
  });
  const [formBody, setFormBody] = useState("name");
  const [loading, setLoading] = useState(false);
  const { handleError } = useError();
  const history = useHistory();

  useEffect(() => {
    mixpanel.track("Create Campaign", {
      Action: "Create",
    });
  }, []);

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (values.type === "personal") {
      setValues((prevState) => {
        return {
          ...prevState,
          deliver_to: "recipients",
        };
      });
    } else {
      setValues((prevState) => {
        return {
          ...prevState,
          deliver_to: "",
        };
      });
    }
  }, [values.type]);

  const onSubmit = async (e) => {
    if (values.deliver_to === "") {
      e.preventDefault();
      handleError({ message: "delivery method can't be empty" });
    } else {
      e.preventDefault();
      setLoading(true);
      const { data, err } = await api.controllers.createOrder(values);
      setLoading(false);

      if (err) {
        handleError(err);
      } else {
        history.push(`/orders/edit/${data.data.id}`);
      }
    }
  };

  useEffect(() => {
    if (values.deliver_to === "company") {
      setValues((prevState) => {
        return {
          ...prevState,
          recipient_count: 0,
        };
      });
    }
    if (values.deliver_to === "recipients") {
      setValues((prevState) => {
        return {
          ...prevState,
          recipient_count: 0,
        };
      });
    }
  }, [values.deliver_to]);

  const body = (
    <form className=" bg-white p-5 flex flex-col" onSubmit={onSubmit}>
      {formBody === "name" && <FormName values={values} handleChange={handleChange} setFormBody={setFormBody} />}

      {formBody === "type" && <FormType values={values} handleChange={handleChange} setFormBody={setFormBody} />}

      {formBody === "delivery" && (
        <FormDelivery values={values} handleChange={handleChange} setFormBody={setFormBody} loading={loading} />
      )}
    </form>
  );

  return <Modal hideHeader={true} title={``} widthClass="w-8/12" onClose={onClose} body={body} />;
}
