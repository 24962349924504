import Modal from "components/Modal/Modal";

export default function GuideModal({ onClose }) {
  const FirstBody = (
    <div className="w-3/4 mx-auto text-center flex flex-col gap-2">
      <div className="text-2xl font-bold mb-4">How we can help you?</div>

      <a
        href="https://api.whatsapp.com/send?phone=6285960883694"
        target="_blank"
        rel="noreferrer"
        className="flex justify-center py-4 px-8 font-bold text-white border bg-tertiary_green_one  rounded-md"
      >
        <img src={require("assets/img/whatsapp-white.svg").default} alt={"whatsapp"} className="w-6 mr-2" />
        <p className="ml-2">Contact Us</p>
      </a>
    </div>
  );

  return (
    <Modal
      hideHeader={true}
      hideClose={true}
      title={``}
      widthClass="w-5/12"
      absolute="absolute bottom-20 right-4"
      onClose={onClose}
      body={FirstBody}
    />
  );
}
