import React, { useEffect, useState } from "react";
import api from "lib/api";
import { useError } from "contexts/error";
// import { saveAs } from "file-saver";
import useDidMountEffect from "lib/useDidMountEffect";
import Modal from "components/Modal/Modal";
import Sample from "../../../../components/Sample/Sample";
import Spinner from "../../../../components/Spinner";

const OrderQuotation = ({ order, loadOrder, step, setStep }) => {
  const [values, setValues] = useState({
    recipient_count: order.recipient_count,
  });
  const [voucher, setVoucher] = useState({
    voucher_code: "",
  });
  // const [loading, setLoading] = useState(false);
  // const [recipientLoading, setRecipientLoading] = useState(false);
  // const [paymentLoading, setPaymentLoading] = useState(false);
  // const [invoiceLoading, setInvoiceLoading] = useState(false);
  const [availableProductsLoading, setAvailableProductsLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [availableProducts, setAvailableProducts] = useState([]);
  const [showInputVoucher, setShowInputVoucher] = useState(false);
  const [openSample, setOpenSample] = useState(false);
  const [acceptProposal, setAcceptProposal] = useState(false);

  const { handleError } = useError();

  const getProducts = async () => {
    const { data, err } = await api.controllers.getOrderProducts(order.id);
    if (!err) {
      setProducts(data.data);
    }
  };

  const getAvailableProducts = async () => {
    setAvailableProductsLoading(true);
    const { data, err } = await api.controllers.getProducts({
      page: 1,
      limit: -1,
      price: `${order.budget * 0.5},${order.budget}`,
      categoryType: "gift",
      personal: true,
    });
    setAvailableProductsLoading(false);
    if (!err) {
      setAvailableProducts(data.data);
    }
  };

  const onDelete = async (id) => {
    const { err } = await api.controllers.deleteProduct(order.id, id);
    if (!err) {
      loadOrder();
      getProducts();
    }
  };

  useEffect(() => {
    loadOrder();
    getProducts();
    if (order.type === "personal" && order.personal_type === "budget") {
      getAvailableProducts();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const giftProducts = products.filter((product) => product.product_category_type === "gift");
  const packagingProducts = products.filter((product) => product.product_category_type === "packaging");
  const decorationProducts = products.filter((product) => product.product_category_type === "decoration");

  // let handleChange = (e) => {
  //   setValues({ ...values, [e.target.name]: e.target.value });
  // };

  useDidMountEffect(() => {
    let onRecipientUpdate = async () => {
      // setRecipientLoading(true);
      const { err } = await api.controllers.patchOrder(order.id, values);
      // setRecipientLoading(false);

      if (err) {
        handleError(err);
      } else {
        loadOrder();
        getProducts();
      }
    };

    onRecipientUpdate();
  }, [values.recipient_count]);

  // let onPayment = async () => {
  //   setPaymentLoading(true);
  //   const { data, err } = await api.controllers.paymentOrder(order.id);
  //   setPaymentLoading(false);

  //   handleError(err);
  //   if (!err) {
  //     window.location.href = `${data.data.payment_link}`;
  //   }
  // };

  // let onInvoice = async () => {
  //   setInvoiceLoading(true);
  //   const { data, err, res } = await api.controllers.downloadInvoice(order.id);
  //   setInvoiceLoading(false);

  //   handleError(err);
  //   if (!err) {
  //     let blob = new Blob([data], { type: "application/pdf" });
  //     saveAs(blob, `invoice-${Math.floor(Math.random() * 100000)}.pdf`, {
  //       autoBom: true,
  //     });
  //   }
  // };

  let onNext = async (e) => {
    setAcceptProposal(true);
    const { err } = await api.controllers.patchOrder(order.id, {
      step: "payment",
    });
    setAcceptProposal(false);
    if (!err) {
      order.activate ? setStep("message") : order.deliver_to === "company" ? setStep("delivery") : setStep("recipient");
    }
  };

  const onSubmitVoucher = async (e) => {
    e.preventDefault();
    // setLoading(true);
    const { err } = await api.controllers.addVoucher(order.id, voucher);
    // setLoading(false);

    if (err) {
      handleError(err);
    } else {
      loadOrder();
      getProducts();
    }
  };

  const onDeleteVoucher = async (e) => {
    e.preventDefault();
    // setLoading(true);
    const { err } = await api.controllers.deleteVoucher(order.id);
    // setLoading(false);

    if (err) {
      handleError(err);
    } else {
      loadOrder();
      getProducts();
    }
  };

  let handleVoucher = (e) => {
    setVoucher({ ...voucher, [e.target.name]: e.target.value });
  };

  let renderProducts = (_products) => {
    return _products.map((product) => {
      let imgUrl =
        process.env.REACT_APP_ENVIRONMENT === "production" ? product.product_image_url : "https://picsum.photos/200";

      return (
        <div key={product.id} className="px-3 flex items-center justify-between">
          <div className="py-3 min-w-max">
            <img src={imgUrl} alt={product.product_name} className="w-16 h-16 rounded-lg mr-3" />
          </div>

          <div className={`py-3 w-7/12 mr-3 px-3 ${product.variations.length > 0 ? "" : "flex items-center"}`}>
            <h4 className="text-sm mb-1"> {product.product_name}</h4>
            {order.type === "branded" && (
              <div className="">
                {product.variations &&
                  product.variations.map((variation, index) => (
                    <span
                      key={`variation-${index}-${variation.name}`}
                      className="text-sm bg-secondaryTwo px-2 py-1 mr-2 mb-2 rounded-lg"
                    >
                      {variation.option}
                    </span>
                  ))}
              </div>
            )}
          </div>

          <p className="text-sm py-3 w-3/12 mr-3 px-3">
            {new Intl.NumberFormat("id-ID", {
              style: "currency",
              currency: "IDR",
              maximumFractionDigits: 0,
            }).format(product.price)}
          </p>

          <div className="cursor-pointer pr-8 py-3 font-bold w-2/12" onClick={() => onDelete(product.id)}>
            <img
              src={require("assets/img/order-edit/cart-delete.svg").default}
              alt={"cart-delete"}
              className="w-6 ml-auto"
            />
          </div>
        </div>
      );
    });
  };

  let waMessage = `Hi Yippy, saya ingin menanyakan mengenai Campaign ${order.id}`;
  return (
    <div>
      {openSample && (
        <Modal
          hideHeader={true}
          title={``}
          widthClass="min-w-[90%]"
          onClose={() => setOpenSample((prev) => !prev)}
          body={<Sample type="sample" order={order} products={giftProducts} />}
        />
      )}
      {/* Quotation */}
      <div className="w-full py-5">
        <div className="px-6 py-11 rounded-lg flex flex-col bg-white border border-gray-300">
          <div className="w-full flex">
            {/* left part  */}
            <div className="w-full pr-5 mb-2">
              {/* sample info  */}
              {order.type === "branded" && order.sampling && (
                <div className="relative text-sm flex items-center justify-between bg-blue-100 rounded-md border border-blue-500 p-3 mb-5">
                  <div className="flex items-center text-blue-500 select-none">
                    <img src={require("assets/img/order-edit/info.svg").default} alt={"info"} className="w-6 mr-2" />
                    Sample is estimated to be completed on{" "}
                    <span className="font-bold underline ml-1">{order.sample_estimated_date}</span>
                  </div>

                  <button
                    onClick={() => setOpenSample((prev) => !prev)}
                    className="text-white w-fit bg-primary px-3 py-1 rounded-md"
                  >
                    See Samples
                  </button>
                </div>
              )}

              <div className="items-center w-full bg-transparent border-collapse overflow-hidden">
                <div className="bg-gray-100 px-3 flex justify-between">
                  <p className="text-left py-3 font-bold w-16">Item</p>
                  <p className={`text-left py-3 font-bold w-7/12 mr-24`}></p>
                  <p className="text-left py-3 font-bold mr-10 w-3/12">Price</p>

                  <p className={`text-left py-3 pr-2 font-bold w-2/12 ${order.type !== "branded" ? "-mr-8" : ""}`}>
                    &nbsp;
                  </p>
                </div>

                {products.length > 0 && giftProducts.length > 0 && (
                  <div>
                    <>
                      {order.type === "branded" && <div className="pl-3 font-bold pt-4">Product</div>}
                      {order.type !== "branded" && <div className="pl-3 font-bold pt-4">Recommendation</div>}
                      {renderProducts(giftProducts)}
                    </>
                    {packagingProducts.length > 0 && (
                      <>
                        {order.type === "branded" && <div className="pl-3 font-bold pt-4">Packaging</div>}
                        {renderProducts(packagingProducts)}
                      </>
                    )}
                    {decorationProducts.length > 0 && (
                      <>
                        {order.type === "branded" && <div className="pl-3 font-bold pt-4">Decoration</div>}
                        {renderProducts(decorationProducts)}
                      </>
                    )}
                  </div>
                )}
              </div>

              {products.length <= 0 && (
                <div className="w-full flex flex-col items-center mt-16 gap-2">
                  <img
                    src={require("assets/img/order-edit/proposal-no-item.png").default}
                    alt={"proposal-no-item"}
                    className="w-60 mx-auto"
                  />
                  <p className="text-2xl font-bold">
                    {order.type === "personal" && order.personal_type === "budget" ? (
                      availableProductsLoading ? (
                        <Spinner color={"black"} />
                      ) : (
                        `${availableProducts.length} gifts available`
                      )
                    ) : (
                      "Gift is empty"
                    )}
                  </p>
                  <p className="">The gift item will be selected by recipients</p>
                </div>
              )}
            </div>

            {/* right part  */}
            <div className="w-6/12 h-fit px-5 py-3 bg-gray-100 rounded-lg">
              <div className="flex justify-between mb-2">
                <p className="font-bold">
                  {order.deliver_to === "company" && "Quantity"}
                  {order.deliver_to !== "company" && "Recipients"}
                </p>

                <div className="flex items-center gap-x-5">
                  <button
                    disabled={values.recipient_count === 1}
                    onClick={() =>
                      setValues((values) => ({
                        ...values,
                        recipient_count: values.recipient_count - 1,
                      }))
                    }
                    className={`w-fit cursor-pointer ${order.free ? "hidden" : ""}`}
                  >
                    <img src={require("assets/img/order-edit/min.svg").default} alt={"min"} className="w-4" />
                  </button>
                  <div className="font-medium">{values.recipient_count}</div>
                  <button
                    onClick={() =>
                      setValues((values) => ({
                        ...values,
                        recipient_count: values.recipient_count + 1,
                      }))
                    }
                    className={`w-fit cursor-pointer ${order.free ? "hidden" : ""}`}
                  >
                    <img src={require("assets/img/order-edit/plus.svg").default} alt={"plus"} className="w-4" />
                  </button>
                </div>
              </div>

              {/* budget  */}
              {order.type === "personal" && order.personal_type === "budget" && (
                <div className="flex justify-between mb-2">
                  <p className="font-bold">Budget :</p>
                  <p className="text-left font-medium">
                    {new Intl.NumberFormat("id-ID", {
                      style: "currency",
                      currency: "IDR",
                      maximumFractionDigits: 0,
                    }).format(order.budget)}
                  </p>
                </div>
              )}

              {/* subtotal  */}
              {order.type === "branded" && (
                <div className="flex justify-between mb-2">
                  <p className="font-bold">Price / package</p>
                  <p className="text-left font-medium">
                    {new Intl.NumberFormat("id-ID", {
                      style: "currency",
                      currency: "IDR",
                      maximumFractionDigits: 0,
                    }).format(order.products_price)}
                  </p>
                </div>
              )}

              <div className="flex justify-between mb-2">
                <p className="font-bold">Subtotal {order.type === "personal" && " (Estimated)"} :</p>
                <p className="text-left font-medium">
                  {order.free && "FREE"}
                  {!order.free && order.type === "branded" && (
                    <>
                      {new Intl.NumberFormat("id-ID", {
                        style: "currency",
                        currency: "IDR",
                        maximumFractionDigits: 0,
                      }).format(order.products_price * order.recipient_count)}
                    </>
                  )}
                  {!order.free && order.type === "personal" && order.personal_type === "budget" && (
                    <>
                      {new Intl.NumberFormat("id-ID", {
                        style: "currency",
                        currency: "IDR",
                        maximumFractionDigits: 0,
                      }).format(order.budget * order.recipient_count)}
                    </>
                  )}
                  {!order.free && order.type === "personal" && order.personal_type === "items" && (
                    <>
                      {new Intl.NumberFormat("id-ID", {
                        style: "currency",
                        currency: "IDR",
                        maximumFractionDigits: 0,
                      }).format(order.products_price * order.recipient_count)}
                    </>
                  )}
                </p>
              </div>
              {!order.free && order.type === "personal" && (
                <div className="relative text-sm flex text-blue-500 bg-blue-100 rounded-md border border-blue-500 p-3 my-2">
                  <img src={require("assets/img/order-edit/info.svg").default} alt={"info"} className="w-6 mr-2" />{" "}
                  Final subtotal depends on the gifts that recipient choose.
                </div>
              )}

              {/* service fee if personal  */}
              {/* {order.type === "personal" && (
                <>
                  <div className="flex justify-between mb-2">
                    <p className="font-bold">Service Fee</p>
                    <p className="text-left font-medium">
                      {new Intl.NumberFormat("id-ID", {
                        style: "currency",
                        currency: "IDR",
                        maximumFractionDigits: 0,
                      }).format(order.recipient_count * 10000)}
                    </p>
                  </div>
                </>
              )} */}

              {/* shipping  */}
              <div className="flex justify-between mb-2">
                <p className="font-bold">Shipping :</p>
                <p className="text-left font-medium">
                  {/*
                  {order.type === "personal" && (
                    <>
                      {new Intl.NumberFormat("id-ID", {
                        style: "currency",
                        currency: "IDR",
                        maximumFractionDigits: 0,
                      }).format(23000 * order.recipient_count)}
                    </>
                  )}
                    */}
                  {order.free ? "FREE" : "Contact Us"}
                </p>
              </div>
              {!order.free && order.type === "personal" && (
                <div className="relative text-sm flex text-blue-500 bg-blue-100 rounded-md border border-blue-500 p-3 my-2">
                  <img src={require("assets/img/order-edit/info.svg").default} alt={"info"} className="w-6 mr-2" />{" "}
                  Shipping cost depends on recipients location.
                </div>
              )}
              {!order.free && order.type !== "personal" && (
                <div className="relative text-sm flex text-blue-500 bg-blue-100 rounded-md border border-blue-500 p-3 my-2">
                  <img src={require("assets/img/order-edit/info.svg").default} alt={"info"} className="w-6 mr-2" />{" "}
                  Shipping cost depends on your location.
                </div>
              )}

              {/* voucher & discount  */}
              {order.voucher_code !== null && (
                <>
                  <div className="flex justify-between mb-2">
                    <p className="font-bold py-2">Voucher :</p>
                    <div className="flex gap-2 font-medium bg-secondaryTwo py-2 px-2">
                      <p className="pl-2 border-l border-dashed border-primary text-primary font-bold">
                        {order.voucher_code}
                      </p>
                      <div className="cursor-pointer " onClick={onDeleteVoucher}>
                        <img
                          src={require("assets/img/order-edit/voucher-delete-orange.svg").default}
                          alt={"voucher-delete-orange"}
                          className="w-6"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-between mb-2 font-medium">
                    <p className="font-bold">Discount :</p>
                    {new Intl.NumberFormat("id-ID", {
                      style: "currency",
                      currency: "IDR",
                      maximumFractionDigits: 0,
                    }).format(order.discount_amount)}
                  </div>
                </>
              )}

              {/* total amount  */}
              <div className="flex justify-between mb-3 pt-4 border-t border-dashed border-primaryTwo mt-5">
                <p className="font-bold">Total Amount (IDR) :</p>
                <p className="text-left font-medium">
                  {order.free ? (
                    "FREE"
                  ) : (
                    <>
                      {order.type === "personal" && (
                        <>
                          {new Intl.NumberFormat("id-ID", {
                            style: "currency",
                            currency: "IDR",
                            maximumFractionDigits: 0,
                          }).format(order.total_price)}
                        </>
                      )}
                      {order.type === "branded" && (
                        <>
                          {new Intl.NumberFormat("id-ID", {
                            style: "currency",
                            currency: "IDR",
                            maximumFractionDigits: 0,
                          }).format(order.total_price)}
                        </>
                      )}
                    </>
                  )}
                </p>
              </div>

              {/* CTA  */}
              <>
                {!order.free && (
                  <a
                    href={`https://wa.me/6285960883694?text=${encodeURI(waMessage)}`}
                    target="_blank"
                    rel="noreferrer"
                    className="flex justify-center mb-3 py-4 px-8 font-bold text-white border bg-tertiary_green_one  rounded-md"
                  >
                    <img src={require("assets/img/whatsapp-white.svg").default} alt={"whatsapp"} className="w-6 mr-2" />
                    <p className="ml-2">Contact Us</p>
                  </a>
                )}

                {!order.free && order.voucher_code === null && (
                  <>
                    {!showInputVoucher && (
                      <div
                        onClick={() => setShowInputVoucher((prev) => !prev)}
                        className="flex justify-center py-4 px-8 text-blue-500 cursor-pointer"
                      >
                        <p className="underline hover:font-medium">Have a promo code?</p>
                      </div>
                    )}

                    {showInputVoucher && (
                      <div className="relative flex justify-center items-center">
                        <img
                          src={require("assets/img/order-edit/voucher.svg").default}
                          alt={"voucher"}
                          className="w-6 mr-2 absolute left-2"
                        />

                        <input
                          name="voucher_code"
                          value={voucher.voucher_code}
                          onChange={handleVoucher}
                          type="search"
                          className="border-0 py-3 pl-12 placeholder-slate-300 text-slate-600 bg-white rounded-l text-sm shadow w-full"
                          placeholder="Voucher Code"
                        />
                        <div
                          onClick={onSubmitVoucher}
                          className="bg-tertiary_light_blue text-white font-bold uppercase text p-3   rounded-r shadow cursor-pointer"
                        >
                          Apply
                        </div>
                      </div>
                    )}
                  </>
                )}
              </>
            </div>
          </div>

          {/* invoice */}
          <>
            {/* {!order.recurring && (
            <>
              {order.type === "branded" && (
                <div className="flex justify-center mb-2 mt-4 gap-x-2">
                  <button
                    className="flex justify-center items-center bg-gray-400 text-white uppercase font-bold text-md px-4 py-2 rounded shadow"
                    onClick={onInvoice}
                  >
                    {invoiceLoading && <Spinner size={4} />} Invoice
                  </button>

                  {order.need_payment && (
                    <>
                      <button
                        className="flex justify-center items-center bg-primary text-white uppercase font-bold text-md px-4 py-2 rounded shadow"
                        onClick={onPayment}
                      >
                        {paymentLoading && <Spinner size={4} />} Payment
                      </button>
                    </>
                  )}
                </div>
              )}
            </>
          )} */}
          </>
          {/* invoice */}
        </div>
      </div>

      <div className="flex justify-between">
        <div
          className="flex mb-2 py-4 px-8 font-bold text-primary border w-fit bg-white border-primary rounded-md cursor-pointer"
          onClick={() => setStep("item")}
        >
          <img
            src={require("assets/img/arrow-right.svg").default}
            alt={"arrow-left"}
            className="w-6 mr-2 rotate-180 svg_orange"
          />
          <p className="mr-2">Change Item</p>
        </div>

        <div
          className="flex justify-center mb-2 py-4 px-8 font-bold text-white border md:w-fit bg-primary border-primary rounded-md cursor-pointer"
          onClick={onNext}
        >
          <p className="mr-2 flex">
            {acceptProposal && <Spinner />}
            Add Recipient
          </p>
          <img src={require("assets/img/arrow-right.svg").default} alt={"arrow-left"} className="w-6 ml-2" />
        </div>
      </div>
    </div>
  );
};

export default OrderQuotation;
