import React from "react";
import { Switch, Route } from "react-router-dom";

// components

// views

import Login from "views/auth/Login.js";
import Register from "views/auth/Register.js";
import Verify from "views/auth/Verify";
import ResetPassword from "views/auth/ResetPassword";
import VerifyPassword from "views/auth/VerifyPassword";

export default function Auth() {
  return (
    <>
      <main>
        <section className="relative w-full h-full min-h-screen">
          <div
            className="absolute top-0 w-full h-full bg-primaryTwo bg-no-repeat bg-full auth_main"
            style={{}}
          ></div>
          <Switch>
            <Route path="/auth/login" exact component={Login} />
            <Route path="/auth/register" exact component={Register} />
            <Route path="/auth/verification/:token" exact component={Verify} />
            <Route
              path="/auth/reset-password"
              exact
              component={ResetPassword}
            />
            <Route
              path="/auth/reset-password/:token"
              exact
              component={VerifyPassword}
            />
          </Switch>
        </section>
      </main>
    </>
  );
}
