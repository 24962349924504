import React, { useCallback, useEffect, useState } from "react";

import api from "lib/api";
import { useParams } from "react-router";
import Spinner from "components/Spinner";
import OrderProducts from "./Detail/OrderProducts";
import OrderRecipients from "./Detail/OrderRecipients";
import OrderConditions from "./Detail/OrderConditions";
import mixpanel from "lib/mixpanel";
import CardStats from "components/Cards/CardStats";
import OrderReviews from "./Detail/OrderReviews";
import OrderMessage from "./Detail/OrderMessage";
import Sample from "components/Sample/Sample";
import OrderSchedule from "./Detail/OrderSchedule";
import { useLocation, useHistory } from "react-router";
import OrderPersonalTypeItems from "./Edit/OrderPersonalTypeItems";
import OrderPersonalTypeBudget from "./Edit/OrderPersonalTypeBudget";

export default function OrderDetail() {
  const [order, setOrder] = useState(null);
  const { id } = useParams();
  const [tab, setTab] = useState("recipients");
  let history = useHistory();
  let query = useLocation();

  const fetchOrder = useCallback(
    async (firstLoad) => {
      const { data, err } = await api.controllers.getOrder(id);
      if (!err) {
        const _order = data.data;
        setOrder(_order);

        if (firstLoad === true) {
          mixpanel.track("Show Campaign", {
            Campaign: _order.name,
          });
        }
      }
    },
    [id]
  );

  useEffect(() => {
    fetchOrder(true);
  }, [fetchOrder]);

  useEffect(() => {
    if (query.hash) {
      let str = query.hash.replace(/#/g, "");
      setTab(str);
    } else if (tab !== "recipients") {
      history.push(`${window.location.pathname}#${tab}`);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {!order && (
        <div className="flex justify-center">
          <Spinner color="black" />
        </div>
      )}
      {order && (
        <>
          <div className="bg-white px-5 pt-5 pb-2 rounded-lg mb-10">
            <h1 className=" text-2xl font-medium mb-5">
              {order.name}
              {order.free && (
                <span className="px-2 py-1 text-sm text-white bg-primary rounded-md ml-2 -mt-1">Free</span>
              )}{" "}
            </h1>
            <div className="grid grid-cols-4 gap-5">
              <div className="w-full py-2">
                <CardStats
                  border="border py-1"
                  statSubtitle="Unclaimed"
                  statTitle={`${order.recipient_count - order.address_completed_count}`}
                  maxValue={order.recipient_count}
                  circleColor="rgba(196, 52, 138, 1)"
                  trailColor="rgba(196, 52, 138, 0.5)"
                  borderBottom="border-b-pink-600"
                />
              </div>
              <div className="w-full py-2">
                <CardStats
                  border="border py-1"
                  statSubtitle="Processing"
                  statTitle={`${order.processing_count - order.delivering_count}`}
                  maxValue={order.processing_count}
                  circleColor="rgba(83, 165, 138, 1)"
                  trailColor="rgba(83, 165, 138, 0.5)"
                  borderBottom="border-b-teal-500"
                />
              </div>
              <div className="w-full py-2">
                <CardStats
                  border="border py-1"
                  statSubtitle="Delivering"
                  statTitle={`${order.delivering_count - order.delivered_count}`}
                  maxValue={order.delivering_count}
                  circleColor="rgba(234, 196, 81, 1)"
                  trailColor="rgba(234, 196, 81, 0.5)"
                  borderBottom="border-b-amber-400"
                />
              </div>
            </div>
          </div>

          <div className="bg-white p-5 mb-10 rounded-lg">
            <div className="flex justify-center mb-5 relative">
              <div className="absolute w-full h-1 bottom-0 right-0 left-0 bg-gray-200"></div>
              <div className="bg-white rounded-lg z-10">
                <button
                  className={`border-b-4 px-10 py-4 font-bold ${
                    tab === "recipients" ? "border-primary text-primary" : "border-tertiary_gray_two text-black"
                  }`}
                  onClick={() => {
                    setTab("recipients");
                    history.push(`${window.location.pathname}`);
                  }}
                >
                  <i className={` fas fa-users mr-2`} /> Recipients
                </button>

                {order.type === "personal" && (
                  <button
                    className={`border-b-4 px-10 py-4 font-bold ${
                      tab === "gift-options" ? "border-primary text-primary" : "border-tertiary_gray_two"
                    }`}
                    onClick={() => {
                      setTab("gift-options");
                      history.push(`${window.location.pathname}#gift-options`);
                    }}
                  >
                    <i className={`fas fa-gift mr-2`} /> Gift Options
                  </button>
                )}

                {order.type !== "personal" && order.product_count > 0 && (
                  <button
                    className={`border-b-4 px-10 py-4 font-bold ${
                      tab === "items" ? "border-primary text-primary" : "border-tertiary_gray_two"
                    }`}
                    onClick={() => {
                      setTab("items");
                      history.push(`${window.location.pathname}#items`);
                    }}
                  >
                    <i className={` fas fa-archive box mr-2`} /> Items
                  </button>
                )}

                {order.type === "branded" && order.production_estimated_date && (
                  <button
                    className={`border-b-4 px-10 py-4 font-bold ${
                      tab === "production" ? "border-primary text-primary" : "border-tertiary_gray_two"
                    }`}
                    onClick={() => {
                      setTab("production");
                      history.push(`${window.location.pathname}#production`);
                    }}
                  >
                    <i className={` fas fa-boxes gifts mr-2`} /> Production
                  </button>
                )}

                {order.deliver_to !== "company" && (
                  <button
                    className={`border-b-4 px-10 py-4 font-bold ${
                      tab === "reviews" ? "border-primary text-primary" : "border-tertiary_gray_two"
                    }`}
                    onClick={() => {
                      setTab("reviews");
                      history.push(`${window.location.pathname}#reviews`);
                    }}
                  >
                    <i className={` fas fa-star thumbs-up mr-2`} /> Reviews
                  </button>
                )}

                {order.recurring && (
                  <button
                    className={`border-b-4 px-10 py-4 font-bold ${
                      tab === "conditions" ? "border-primary text-primary" : "border-tertiary_gray_two"
                    }`}
                    onClick={() => {
                      setTab("conditions");
                      history.push(`${window.location.pathname}#conditions`);
                    }}
                  >
                    <i className={` fas fa-cog mr-2`} /> Conditions
                  </button>
                )}

                {order.recurring && (
                  <button
                    className={`border-b-4 px-10 py-4 font-bold ${
                      tab === "schedule" ? "border-primary text-primary" : "border-tertiary_gray_two"
                    }`}
                    onClick={() => {
                      setTab("schedule");
                      history.push(`${window.location.pathname}#schedule`);
                    }}
                  >
                    <i className={` fas fa-calendar-alt mr-2`} /> Schedule
                  </button>
                )}

                {order.deliver_to !== "company" && (
                  <button
                    className={`border-b-4 px-10 py-4 font-bold ${
                      tab === "message" ? "border-primary text-primary" : "border-tertiary_gray_two"
                    }`}
                    onClick={() => {
                      setTab("message");
                      history.push(`${window.location.pathname}#message`);
                    }}
                  >
                    <i className={`fas fa-comment-dots mr-2`} /> Message
                  </button>
                )}
              </div>
            </div>

            {tab === "recipients" && <OrderRecipients order={order} />}
            {tab === "gift-options" && (
              <>
                {order.personal_type === "items" && <OrderPersonalTypeItems order={order} />}

                {order.personal_type === "budget" && <OrderPersonalTypeBudget order={order} loadOrder={fetchOrder} />}
              </>
            )}
            {tab === "reviews" && <OrderReviews order={order} />}
            {tab === "items" && <OrderProducts order={order} />}
            {tab === "production" && <Sample order={order} hideTitle={true} type="production" />}
            {tab === "conditions" && <OrderConditions order={order} />}
            {tab === "schedule" && <OrderSchedule order={order} />}
            {tab === "message" && <OrderMessage order={order} />}
          </div>
        </>
      )}
    </>
  );
}
