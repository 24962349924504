import React from "react";
import { Link } from "react-router-dom";

function OrderCampaign({ orders }) {
  let statusMap = {
    draft: "Draft",
    processing: "Proccessing",
    completed: "Completed",
    expired: "Expired",
  };

  return (
    <div>
      {orders &&
        orders.map((order, index) => {
          let unclaimed = order.recipient_count - order.address_completed_count;

          return (
            <Link
              key={`order-${index}`}
              to={order.status === "draft" ? `/orders/edit/${order.id}` : `/orders/${order.id}`}
            >
              <div className="relative flex justify-center items-center min-w-0 break-words bg-white rounded-lg mb-3 py-6 pl-8 border border-gray-300 text-center">
                <div className="text-left w-6/12">
                  <p className="text-xl font-medium mb-2">{order.name}</p>
                  <p className="text-sm text-gray-400 flex items-center">
                    <span className="mr-2">{new Date(order.created_at).toLocaleDateString("id-ID")}</span>
                    {order.type === "branded" && (
                      <span className="py-1 px-2 bg-gray-200 text-xs rounded-lg font-bold mr-2">Branded</span>
                    )}
                    {order.type === "personal" && (
                      <span className="py-1 px-2 bg-gray-200 text-xs rounded-lg font-bold mr-2">Smart</span>
                    )}
                    {order.deliver_to === "company" && (
                      <span className="py-1 px-2 bg-gray-200 text-xs rounded-lg font-bold mr-2">Single address</span>
                    )}
                    {order.deliver_to === "recipients" && (
                      <span className="py-1 px-2 bg-gray-200 text-xs rounded-lg font-bold mr-2">To recipients</span>
                    )}
                    {order.recurring && (
                      <span className="py-1 px-2 bg-gray-200 text-xs rounded-lg font-bold mr-2">recurring</span>
                    )}
                    {order.free && (
                      <span className="py-1 px-2 bg-primary text-white text-xs rounded-lg font-bold mr-2">Free</span>
                    )}
                  </p>
                </div>

                <div className="w-2/12">
                  <p>Status</p>
                  <p>{statusMap[order.status]}</p>
                </div>

                {order.deliver_to === "company" && (
                  <>
                    <div className="w-2/12">
                      <p>Quantity</p>
                      <p>{order.recipient_count}</p>
                    </div>
                    <div className="w-2/12">
                      <p></p>
                      <p></p>
                    </div>
                  </>
                )}

                {order.deliver_to !== "company" && (
                  <>
                    <div className="w-2/12">
                      <p>Claimed</p>
                      <p>{order.address_completed_count}</p>
                    </div>

                    <div className="w-2/12">
                      <p>Unclaimed</p>
                      <p>{unclaimed}</p>
                    </div>
                  </>
                )}

                <div className="w-1/12">
                  <i className="fas fa-arrow-right" />
                </div>
              </div>
            </Link>
          );
        })}
    </div>
  );
}

export default OrderCampaign;
