import React, { useCallback, useEffect, useState } from "react";
import api from "lib/api";
import { useError } from "contexts/error";
import Map from "components/map/Map";

const OrderDelivery = ({ order, setStep }) => {
  const [loading, setLoading] = useState(false);
  const [provinceList, setProvinceList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [subsdistrictList, setSubsdistrictList] = useState([]);
  const { handleError } = useError();
  const [recipients, setRecipients] = useState([]);
  const [reverseGeocodeDone, setReverseGeocodeDone] = useState(false);

  const [values, setValues] = useState({
    country: null,
    name: "",
    email: "",
    phone_number: "",
    province_id: null,
    city_id: null,
    subdistrict_id: null,
    address: "",
    postal_code: "",
    longitude: 106.816667, //default jakarta
    latitude: -6.2, //default jakarta
  });

  // for geocode
  const [subdistrictIndex, setSubdistrictIndex] = useState(null);
  const [subdistrictName, setSubdistrictName] = useState(null);
  const [cityIndex, setCityIndex] = useState(null);
  const [cityName, setCityName] = useState(null);
  const [addressForLongLat, setAddressForLongLat] = useState("");

  useEffect(() => {
    values.subdistrict_id &&
      setSubdistrictIndex(
        subsdistrictList.findIndex(function (data) {
          return data.id === parseInt(values.subdistrict_id);
        })
      );
  }, [values.subdistrict_id, subsdistrictList]);

  useEffect(() => {
    values.city_id &&
      setCityIndex(
        cityList.findIndex(function (data) {
          return data.id === parseInt(values.city_id);
        })
      );
  }, [values.city_id, cityList]);

  useEffect(() => {
    setSubdistrictIndex(null);
    setCityIndex(null);
  }, [values.country, values.province_id]);

  useEffect(() => {
    subdistrictIndex !== null && subdistrictIndex !== -1 && setSubdistrictName(subsdistrictList[subdistrictIndex].name);
  }, [subdistrictIndex, subsdistrictList]);

  useEffect(() => {
    cityIndex !== null && cityIndex !== -1 && setCityName(cityList[cityIndex].name);
  }, [cityIndex, cityList]);

  useEffect(() => {
    if (subdistrictName && cityName) {
      if (values.address.toLocaleLowerCase().indexOf(cityName.toLocaleLowerCase()) === -1) {
        if (values.address.toLocaleLowerCase().indexOf(subdistrictName.toLocaleLowerCase()) === -1) {
          setAddressForLongLat(`${values.address}, ${cityName}, ${subdistrictName}`);
        } else {
          setAddressForLongLat(`${values.address}, ${cityName}`);
        }
      } else {
        if (values.address.toLocaleLowerCase().indexOf(subdistrictName.toLocaleLowerCase()) === -1) {
          setAddressForLongLat(`${values.address}, ${subdistrictName}`);
        } else {
          setAddressForLongLat(`${values.address}`);
        }
      }
    }
  }, [subdistrictName, cityName, values.address]);

  useEffect(() => {
    setReverseGeocodeDone(false);
  }, [addressForLongLat]);
  // for geocode

  let provId = provinceList.findIndex(function (data) {
    return data.name === recipients?.province;
  });

  let cityId = cityList.findIndex(function (data) {
    return data.name === recipients?.city;
  });

  let subsdistricId = subsdistrictList.findIndex(function (data) {
    return data.name === recipients?.subdistrict;
  });

  useEffect(() => {
    provId !== -1 &&
      setValues((prevState) => {
        return {
          ...prevState,
          province_id: provinceList[provId].id,
        };
      });
  }, [provId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    cityId !== -1 &&
      setValues((prevState) => {
        return {
          ...prevState,
          city_id: cityList[cityId].id,
        };
      });
  }, [cityId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    subsdistricId !== -1 &&
      setValues((prevState) => {
        return {
          ...prevState,
          subdistrict_id: subsdistrictList[subsdistricId].id,
        };
      });
  }, [subsdistricId]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchOrderRecipients = useCallback(async () => {
    const { data, err } = await api.controllers.getOrderRecipients(order.id);
    if (!err) {
      let _recipients = data.data[0];

      setRecipients(_recipients);

      data.data.length > 0 &&
        setValues((prevState) => {
          return {
            ...prevState,
            country: _recipients.country,
            name: _recipients.recipient_name,
            email: _recipients.recipient_email,
            phone_number: _recipients.phone_number,
            province_id: null,
            city_id: null,
            subdistrict_id: null,
            address: _recipients.address,
            postal_code: _recipients.postal_code,
            longitude: parseFloat(_recipients.longitude),
            latitude: parseFloat(_recipients.latitude),
          };
        });
    }
  }, [order.id]);

  const fetchRegions = useCallback(async () => {
    const { data, err } = await api.controllers.getRegions({
      type: "province",
    });

    if (!err) {
      setProvinceList(data.data);
    }
  }, []);

  useEffect(() => {
    fetchOrderRecipients();
    fetchRegions();
  }, [fetchOrderRecipients, fetchRegions]);

  // city
  const getCity = async () => {
    if (loading) return;
    setLoading(true);
    const { data, err } = await api.controllers.getRegions({
      type: "city",
      province_id: values.province_id,
    });
    setLoading(false);

    if (err) {
      handleError(err);
    } else {
      setCityList(data.data); // eslint-disable-line react-hooks/exhaustive-deps
    }
  };

  // city
  useEffect(() => {
    if (values.province_id !== null) {
      getCity();
    }
  }, [values.province_id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let newCityId = cityList.findIndex(function (data) {
      return data.name === recipients?.city;
    });

    if (newCityId === -1) {
      setValues((prevState) => {
        return {
          ...prevState,
          city_id: null,
          subdistrict_id: null,
        };
      });
    }
  }, [cityList]); // eslint-disable-line react-hooks/exhaustive-deps

  // subdistrict
  const getSubdistric = async () => {
    if (loading) return;
    setLoading(true);
    const { data, err } = await api.controllers.getRegions({
      type: "subdistrict",
      province_id: values.province_id,
      city_id: values.city_id,
    });
    setLoading(false);

    if (err) {
      handleError(err);
    } else {
      setSubsdistrictList(data.data);
    }
  };

  // subdistrict
  useEffect(() => {
    if (values.city_id !== null) {
      getSubdistric();
    }
  }, [values.city_id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let newsubsdistricId = subsdistrictList.findIndex(function (data) {
      return data.name === recipients?.subdistrict;
    });

    if (newsubsdistricId === -1) {
      setValues((prevState) => {
        return {
          ...prevState,
          subdistrict_id: null,
        };
      });
    }
  }, [subsdistrictList]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = async (e) => {
    e.preventDefault();
    const _recipient = [
      {
        country: values.country,
        name: values.name,
        email: values.email,
        phone_number: values.phone_number,
        address: values.address,
        postal_code: values.postal_code,
        ...(values.country === "indonesia" && {
          province_id: values.province_id,
          city_id: values.city_id,
          subdistrict_id: values.subdistrict_id,
          longitude: values.longitude,
          latitude: values.latitude,
        }),
      },
    ];
    setLoading(true);
    const { err } = await api.controllers.addOrderRecipients(order.id, _recipient);
    setLoading(false);

    if (err) {
      handleError(err);
    } else {
      setStep("preview");
    }
  };

  let handleChange = (e) => {
    setReverseGeocodeDone(false);
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  return (
    <>
      <div className="bg-white px-5 pt-5 pb-16 rounded-lg border border-tertiary_gray_two mt-10">
        <div className="border-b border-tertiary_gray_two pb-6 mb-10">
          <h3 className="text-2xl font-bold text-center cursor-pointer">Delivery</h3>
        </div>

        {/* input  */}
        <div className="w-10/12 mx-auto">
          <div className="w-full lg:w-12/12">
            {/* name */}
            {/* <div className="relative w-full mb-8">
              <label className="block text-primaryTwo text-lg font-bold mb-2">
                Name <span className="text-red-400">*</span>
              </label>
              <input
                name="name"
                type="text"
                className="border border-secondaryOne px-3 py-3 placeholder-slate-300 text-primaryTwo rounded-md bg-white focus:outline-none w-full ease-linear transition-all duration-150"
                placeholder="Name Input"
                value={values.name}
                onChange={handleChange}
              />
            </div> */}
            {/* email & phone  */}
            <div
              className={`relative w-full  grid grid-cols-2 gap-5 ${
                values.country !== null && values.country !== "indonesia" ? "" : "mb-8"
              }`}
            >
              <div className="w-full">
                <label className="block text-primaryTwo text-lg font-bold mb-2">
                  Name <span className="text-red-400">*</span>
                </label>
                <input
                  name="name"
                  type="text"
                  className="border-b border-x-0 border-t-0 border-secondaryOne px-3 py-3 placeholder-slate-300 text-primaryTwo bg-tertiary_gray_four focus:ring-0 w-full"
                  placeholder="Name"
                  value={values.name || ""}
                  onChange={handleChange}
                />
              </div>

              <div className="w-full">
                <label className="block text-primaryTwo text-lg font-bold mb-2">
                  Email <span className="text-red-400">*</span>
                </label>
                <input
                  name="email"
                  type="email"
                  className="border-b border-x-0 border-t-0 border-secondaryOne px-3 py-3 placeholder-slate-300 text-primaryTwo bg-tertiary_gray_four focus:ring-0 w-full"
                  placeholder="email@example.com"
                  value={values.email || ""}
                  onChange={handleChange}
                />
              </div>

              <div className="w-full">
                <label className="block text-primaryTwo text-lg font-bold mb-2">
                  Country <span className="text-red-400">*</span>
                </label>
                <select
                  name="country"
                  className="border-b border-x-0 border-t-0 border-secondaryOne px-3 py-3 placeholder-slate-300 text-primaryTwo bg-tertiary_gray_four focus:ring-0 w-full"
                  onChange={handleChange}
                  defaultValue={values.country ? values.country : ""}
                >
                  {values.country !== null ? (
                    <option value={values.country} disabled>
                      {recipients?.country}
                    </option>
                  ) : (
                    <option value="" disabled>
                      Select Country
                    </option>
                  )}

                  <option value="indonesia">Indonesia</option>
                  <option value="singapore">Singapore</option>
                  <option value="malaysia">Malaysia</option>
                  <option value="thailand">Thailand</option>
                  <option value="vietnam">Vietnam</option>
                  <option value="india">India</option>
                  <option value="philippines">Philippines</option>
                </select>
              </div>

              <div className="w-full">
                <label className="block text-primaryTwo text-lg font-bold mb-2">
                  Phone Number <span className="text-red-400">*</span>
                </label>
                <input
                  name="phone_number"
                  type="tel"
                  pattern="^08[0-9]{4,12}$"
                  placeholder="08xxxxxxxxxx"
                  value={values.phone_number}
                  autoComplete="off"
                  onChange={handleChange}
                  minLength={8}
                  maxLength={13}
                  className="border-b border-x-0 border-t-0 border-secondaryOne px-3 py-3 placeholder-slate-300 text-primaryTwo bg-tertiary_gray_four focus:ring-0 w-full"
                />
              </div>
            </div>
            {/* Province & Province  */}
            <div className="relative w-full mb-8 grid grid-cols-2 gap-5">
              {values.country === "indonesia" && values.country === "indonesia" && (
                <div className="w-full">
                  <label className="block text-primaryTwo text-lg font-bold mb-2">
                    Province <span className="text-red-400">*</span>
                  </label>
                  <select
                    name="province_id"
                    className="border-b border-x-0 border-t-0 border-secondaryOne px-3 py-3 placeholder-slate-300 text-primaryTwo bg-tertiary_gray_four focus:ring-0 w-full"
                    onChange={handleChange}
                    defaultValue={values.province_id ? values.province_id : ""}
                  >
                    {values.province_id !== null ? (
                      <option value={values.province_id} disabled>
                        {recipients?.province}
                      </option>
                    ) : (
                      <option value="" disabled>
                        Select Province
                      </option>
                    )}

                    {provinceList?.map((province) => (
                      <option key={province.id} value={province.id}>
                        {province.name}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              {values.country === "indonesia" && values.province_id !== null && (
                <div className="w-full">
                  <label className="block text-primaryTwo text-lg font-bold mb-2">
                    City <span className="text-red-400">*</span>
                  </label>
                  <select
                    name="city_id"
                    className="border-b border-x-0 border-t-0 border-secondaryOne px-3 py-3 placeholder-slate-300 text-primaryTwo bg-tertiary_gray_four focus:ring-0 w-full"
                    onChange={handleChange}
                    defaultValue={values.city_id ? values.city_id : ""}
                  >
                    {values.city_id !== null ? (
                      <option value={values.city_id} disabled>
                        {recipients?.city}
                      </option>
                    ) : (
                      <option value="" disabled>
                        Select City
                      </option>
                    )}

                    {cityList?.map((city) => (
                      <option key={city.id} value={city.id}>
                        {city.name}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </div>
            {/* Subsdistrict & Postal Code  */}
            {values.country === "indonesia" && values.city_id !== null && (
              <div className="relative w-full mb-8 grid grid-cols-2 gap-5">
                <div className="w-full">
                  <label className="block text-primaryTwo text-lg font-bold mb-2">
                    Subsdistrict <span className="text-red-400">*</span>
                  </label>
                  <select
                    name="subdistrict_id"
                    className="border-b border-x-0 border-t-0 border-secondaryOne px-3 py-3 placeholder-slate-300 text-primaryTwo bg-tertiary_gray_four focus:ring-0 w-full"
                    onChange={handleChange}
                    defaultValue={values.subdistrict_id ? values.subdistrict_id : ""}
                  >
                    {values.subdistrict_id !== null ? (
                      <option value={values.subdistrict_id} disabled>
                        {recipients?.subdistrict}
                      </option>
                    ) : (
                      <option value="" disabled>
                        Select Subsdistrict
                      </option>
                    )}

                    {subsdistrictList?.map((subsdistrict) => (
                      <option key={subsdistrict.id} value={subsdistrict.id}>
                        {subsdistrict.name}
                      </option>
                    ))}
                  </select>
                </div>

                {values.subdistrict_id !== null && (
                  <div className="w-full">
                    <label className="block text-primaryTwo text-lg font-bold mb-2">
                      Postal Code <span className="text-red-400">*</span>
                    </label>
                    <input
                      name="postal_code"
                      type="text"
                      className="border-b border-x-0 border-t-0 border-secondaryOne px-3 py-3 placeholder-slate-300 text-primaryTwo bg-tertiary_gray_four focus:ring-0 w-full"
                      placeholder="12345"
                      value={values.postal_code}
                      onChange={handleChange}
                    />
                  </div>
                )}
              </div>
            )}
            {/* Address  */}
            {values.country === "indonesia" && values.subdistrict_id !== null && (
              <div className="w-full">
                <label className="block text-primaryTwo text-lg font-bold mb-2">
                  Address <span className="text-red-400">*</span>
                </label>
                <input
                  name="address"
                  type="text"
                  className="border-b border-x-0 border-t-0 border-secondaryOne px-3 py-3 placeholder-slate-300 text-primaryTwo bg-tertiary_gray_four focus:ring-0 w-full"
                  placeholder="Address in the same subdistrict as you choose above"
                  value={values.address}
                  onChange={handleChange}
                />
              </div>
            )}

            {values.country !== null && values.country !== "indonesia" && (
              <div className="flex gap-5">
                <div className="w-full">
                  <label className="block text-primaryTwo text-lg font-bold mb-2">
                    Address <span className="text-red-400">*</span>
                  </label>
                  <input
                    name="address"
                    type="text"
                    className="border-b border-x-0 border-t-0 border-secondaryOne px-3 py-3 placeholder-slate-300 text-primaryTwo bg-tertiary_gray_four focus:ring-0 w-full"
                    placeholder="Address"
                    value={values.address}
                    onChange={handleChange}
                  />
                </div>

                {values.country !== "indonesia" && (
                  <div className="max-w-200-px">
                    <label className="block text-primaryTwo text-lg font-bold mb-2">
                      Postal Code <span className="text-red-400">*</span>
                    </label>
                    <input
                      name="postal_code"
                      type="text"
                      className="border-b border-x-0 border-t-0 border-secondaryOne px-3 py-3 placeholder-slate-300 text-primaryTwo bg-tertiary_gray_four focus:ring-0 w-full"
                      placeholder="12345"
                      value={values.postal_code}
                      onChange={handleChange}
                    />
                  </div>
                )}
              </div>
            )}

            {values.country !== null &&
              values.country === "indonesia" &&
              values.province_id &&
              values.city_id &&
              values.subdistrict_id &&
              values.address.length > 0 && (
                <Map
                  setValues={setValues}
                  values={values}
                  setReverseGeocodeDone={setReverseGeocodeDone}
                  reverseGeocodeDone={reverseGeocodeDone}
                  addressForLongLat={addressForLongLat}
                />
              )}
          </div>
        </div>
      </div>

      {/* progress button */}
      <div className="flex justify-center items-center gap-x-5 w-fit mx-auto mb-2 mt-8">
        {/* <button
          onClick={() => {
            setStep("message");
          }}
          className="py-3 px-10 w-fit bg-white text-primary font-bold mx-auto flex items-center rounded-lg border border-primary"
        >
          <img
            src={require("assets/img/arrow-right.svg").default}
            alt={"home-no-campaign-icon"}
            className="w-6 mr-2 svg_orange rotate-180"
          />
          <p>Message</p>
        </button> */}

        <button
          className="py-3 px-16 w-fit bg-primary text-white font-bold mx-auto flex items-center rounded-lg"
          onClick={onSubmit}
        >
          <p>Submit</p>
        </button>
      </div>
    </>
  );
};

export default OrderDelivery;
