import Modal from "components/Modal/Modal";
import { useState } from "react";
import ShowMoreText from "react-show-more-text";

export default function Reviews({ reviews }) {
  const [showModalReview, setShowModalReview] = useState(false);
  const [reviewModalData, setReviewModalData] = useState({});

  let renderStar = (star) => {
    return (
      <div className="flex justify-center lg:gap-x-2">
        {!star && <p>-</p>}
        {star > 0 && (
          <>
            {[...Array(5)].map((_, index) => {
              return (
                <div key={`star-${index}`} className={index < star ? "text-amber-400" : "text-tertiary_gray_two"}>
                  <span className="text-xl">&#9733;</span>
                </div>
              );
            })}
          </>
        )}
      </div>
    );
  };

  let reviewBody = (
    <div className="p-2">
      <p className="font-bold text-xl mb-2 text-center">{reviewModalData.recipient_name}</p>
      <p className="mb-8 text-center">&quot;{reviewModalData.review}&quot;</p>
      {reviewModalData.image_url && (
        <>
          <img src={reviewModalData.image_url} alt="review pict" className="w-1/2 mx-auto rounded-xl mb-8" />
        </>
      )}
      <div className="grid grid-cols-3 gap-4 text-center">
        <div className="">
          <p className="font-bold">Happiness</p>
          {renderStar(reviewModalData.happiness)}
        </div>
        <div className="border-x">
          <p className="font-bold">Matching</p>
          {renderStar(reviewModalData.matching)}
        </div>
        <div className="">
          <p className="font-bold">Quality</p>
          {renderStar(reviewModalData.quality)}
        </div>
      </div>
    </div>
  );

  return (
    <>
      {reviews?.length === 0 && (
        <p className="border bg-white rounded-lg p-5 shadow-md">Reviews from your recipients will appear here.</p>
      )}
      <div className="grid grid-cols-2 gap-5">
        {reviews?.length > 0 &&
          reviews.map((review, index) => {
            let imageUrl =
              process.env.REACT_APP_ENVIRONMENT === "production" ? review.image_url : "https://picsum.photos/200";
            let dateOnlyString = review.created_at.substring(0, 10);

            return (
              <div
                key={`review-${index}`}
                className="border bg-white rounded-lg p-5 shadow-md cursor-pointer"
                onClick={() => {
                  setShowModalReview((prev) => !prev);
                  setReviewModalData({
                    recipient_name: review.recipient_name,
                    image_url: imageUrl,
                    review: review.review,
                    happiness: review.happiness,
                    matching: review.matching,
                    quality: review.quality,
                  });
                }}
              >
                <div className="flex justify-between items-center mb-5">
                  <h3 className="font-bold text-xl">{review.recipient_name}</h3>
                  <div className="text-gray-400 font-semibold flex items-center justify-center gap-1">
                    <p className="text-xs">{review.client_name}</p>
                    <p>•</p>
                    <p className="text-xs">{dateOnlyString}</p>
                  </div>
                </div>

                <div className="flex gap-3 mb-5">
                  {imageUrl && <img src={imageUrl} alt={`Review`} className="w-20 h-20 rounded-lg object-contain" />}

                  <div className={`w-full ${!imageUrl ? "h-20" : ""}`}>
                    <ShowMoreText
                      lines={2}
                      more={<span className="text-secondaryOne">Read More</span>}
                      less={<span className="text-secondaryOne">Show Less</span>}
                      keepNewLines={false}
                    >
                      {review.review}
                    </ShowMoreText>
                  </div>
                </div>

                <div className="grid grid-cols-3">
                  <div className="text-center py-2 pr-5 select-none">
                    <h4 className="font-bold">Happiness</h4>
                    {renderStar(review.happiness)}
                  </div>

                  <div className="text-center py-2 px-5 border-x select-none">
                    <h4 className="font-bold">Matching</h4>
                    {renderStar(review.matching)}
                  </div>

                  <div className="text-center py-2 pl-5 select-none">
                    <h4 className="font-bold">Quality</h4>
                    {renderStar(review.quality)}
                  </div>
                </div>
              </div>
            );
          })}
      </div>

      {showModalReview && (
        <Modal
          hideHeader={true}
          title={``}
          body={reviewBody}
          widthClass="w-2/5"
          onClose={() => setShowModalReview((prev) => !prev)}
        />
      )}
    </>
  );
}
