import React, { useCallback, useEffect, useState } from "react";
import api from "lib/api";
import { useParams } from "react-router";
import OrderHeaderSteps from "./Edit/OrderHeaderSteps";
import { useHistory } from "react-router";

import Spinner from "components/Spinner";
import OrderPersonalItems from "./Edit/OrderPersonalItems";
import OrderBrandedItems from "./Edit/OrderBrandedItems";
import OrderQuotation from "./Edit/OrderQuotation";
import OrderEditRecipients from "./Edit/OrderEditRecipients";
import OrderDelivery from "./Edit/OrderDelivery";
import OrderMessage from "./Edit/OrderMessage";
import OrderPreviewRecipients from "./Edit/OrderPreviewRecipients";
import OrderPreviewCompany from "./Edit/OrderPreviewCompany";
import OrderPublished from "./Edit/OrderPublished";
import OrderRecurringRecipients from "./Edit/OrderRecurringRecipients";
import OrderPersonalTypeItems from "./Edit/OrderPersonalTypeItems";
import OrderPersonalTypeBudget from "./Edit/OrderPersonalTypeBudget";

export default function OrderEdit() {
  let history = useHistory();
  const { id } = useParams();
  const [order, setOrder] = useState(null);
  const [step, setStep] = useState("item");

  let setStepBranded = (_order) => {
    let steps = _order.steps;

    if (steps.indexOf("item") === -1) {
      setStep("item");
    } else if (steps.indexOf("packaging") === -1) {
      setStep("packaging");
    } else if (steps.indexOf("payment") === -1) {
      setStep("quotation");
    } else if (steps.indexOf("recipient") === -1) {
      if (_order.deliver_to === "company") {
        setStep("delivery");
      } else {
        setStep("recipient");
      }
    } else if (steps.indexOf("message") === -1 && _order.deliver_to !== "company") {
      setStep("message");
    } else if (_order.status !== "processing") {
      setStep("preview");
    } else {
      setStep("published");
    }
  };
  let setStepPersonal = (_order) => {
    let steps = _order.steps;
    if (!_order.personal_type) {
      setStep("personal_type");
    } else if (steps.indexOf("item") === -1) {
      setStep("item");
    } else if (steps.indexOf("payment") === -1) {
      setStep("quotation");
    } else if (steps.indexOf("recipient") === -1) {
      setStep("recipient");
    } else if (_order.steps.indexOf("message") === -1) {
      setStep("message");
    } else if (_order.status !== "processing") {
      setStep("preview");
    } else {
      setStep("published");
    }
  };

  const fetchOrder = useCallback(
    async (checkStep) => {
      const { data, err } = await api.controllers.getOrder(id);
      if (!err) {
        const _order = data.data;

        if (checkStep === true) {
          if (_order.type === "personal") {
            setStepPersonal(_order);
          } else if (_order.type === "branded") {
            setStepBranded(_order);
          }
        }
        setOrder(_order);
      }
    },
    [id]
  );

  useEffect(() => {
    fetchOrder(true);
  }, [fetchOrder]);

  useEffect(() => {
    history.push(`${window.location.pathname}`);
  }, [step]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className=" p-4 rounded-lg -mt-10 mb-10">
      {!order && (
        <div className="flex justify-center">
          <Spinner color="black" />
        </div>
      )}

      {order && (
        <>
          <OrderHeaderSteps order={order} step={step} />

          {step === "personal_type" && <OrderPersonalItems order={order} setStep={setStep} loadOrder={fetchOrder} />}

          {step === "item" && order.personal_type === "items" && (
            <OrderPersonalTypeItems order={order} setStep={setStep} />
          )}

          {step === "item" && order.personal_type === "budget" && (
            <OrderPersonalTypeBudget order={order} setStep={setStep} loadOrder={fetchOrder} />
          )}

          {(step === "item" || step === "packaging" || step === "decoration") && order.type === "branded" && (
            <OrderBrandedItems order={order} step={step} setStep={setStep} />
          )}

          {step === "quotation" && (
            <OrderQuotation order={order} step={step} setStep={setStep} loadOrder={fetchOrder} />
          )}

          {order.type === "personal" && step === "recipient" && !order.recurring && (
            <OrderEditRecipients order={order} setStep={setStep} />
          )}

          {order.type === "personal" && step === "recipient" && order.recurring && (
            <OrderRecurringRecipients order={order} setStep={setStep} />
          )}

          {order.type === "branded" && order.deliver_to === "company" && step === "delivery" && (
            <OrderDelivery order={order} setStep={setStep} />
          )}

          {order.type === "branded" && order.deliver_to !== "company" && step === "recipient" && (
            <OrderEditRecipients order={order} setStep={setStep} />
          )}

          {order.deliver_to === "recipients" && step === "message" && <OrderMessage order={order} setStep={setStep} />}

          {order.deliver_to === "recipients" && step === "preview" && (
            <OrderPreviewRecipients order={order} setStep={setStep} loadOrder={fetchOrder} />
          )}

          {order.deliver_to === "company" && step === "preview" && (
            <OrderPreviewCompany order={order} setStep={setStep} loadOrder={fetchOrder} />
          )}

          {step === "published" && <OrderPublished order={order} />}
        </>
      )}
    </div>
  );
}
