import React, { useEffect, useState } from "react";

import api from "lib/api";
import Pagination from "components/Pagination";

export default function ModalOrderEditRecipients({ setRecipients, recipients, onClose, order }) {
  const [data, setData] = useState({});
  const [pagination, setPagination] = useState(null);
  const [orderRecipients, setOrderRecipients] = useState([]);
  const [tempRecipients, setTempRecipients] = useState([]);
  const [checkId, setCheckId] = useState([]);
  const [fixId, setFixId] = useState([]);
  let arrayAllId = [];

  const [filterValues, setFilterValues] = useState({});

  const [filterChange, setFilterChange] = useState({ q: "" });

  useEffect(() => {
    setTempRecipients(
      orderRecipients.map((d) => {
        return {
          select: false,
          id: d.id,
          name: d.recipient_name || d.name,
          email: d.recipient_email || d.email,
          phone_number: d.recipient_phone_number || d.phone_number,
        };
      })
    );
  }, [orderRecipients]);

  const handleFilterChange = (e) => {
    setFilterChange({ ...filterChange, [e.target.name]: e.target.value });
  };

  const onPageChange = (page, limitPerPage) => {
    setPagination({ page: page, limit: limitPerPage ? limitPerPage : 20 });
  };

  useEffect(() => {
    if (pagination) {
      const get = async () => {
        const { data, err } = await api.controllers.getRecipients(pagination.page, pagination.limit, filterValues);
        if (!err) {
          setData(data);
          setOrderRecipients(data.data);
        }
      };
      get();
    }
  }, [pagination, filterValues]);

  const onFilter = async (_values) => {
    setFilterValues(_values);
  };

  const onFilterSubmit = (e) => {
    e.preventDefault();

    onFilter(filterChange);
  };

  let onQueryEnter = (e) => {
    if (e.key === "Enter") {
      onFilter(filterChange);
    }
  };

  const handleAllCheckBox = (e) => {
    const { checked } = e.target;

    setTempRecipients(
      tempRecipients.map((d) => {
        d.select = checked;
        return d;
      })
    );

    tempRecipients.map((ref, i) => {
      return ref.select
        ? (arrayAllId[i] = {
            id: ref.id,
            phone_number: ref.phone_number,
            name: ref.name,
            email: ref.email,
          })
        : arrayAllId;
    });

    checked ? setCheckId([...checkId, ...arrayAllId]) : setCheckId([]);
  };

  useEffect(() => {
    const uniqueIds = [];
    setFixId(
      checkId.filter((element) => {
        const isDuplicate = uniqueIds.includes(element.id);

        if (!isDuplicate) {
          uniqueIds.push(element.id);

          return true;
        }

        return false;
      })
    );
  }, [checkId]);

  let renderRecipients = (_recipients) => {
    return _recipients?.map((recipient, i) => {
      return (
        <div key={recipient.id} className="flex justify-between px-3 py-3 border border-gray-300 my-2 rounded-md">
          <div className="mb-1 w-1/12">
            <input
              type="checkbox"
              name={recipient.name}
              value={recipient.email}
              checked={fixId.some((obj) => obj.id === recipient.id) ? true : recipient.select}
              onChange={(event) => {
                const { name, id, value, checked } = event.target;
                setTempRecipients(
                  tempRecipients.map((data) => {
                    if (recipient.id === data.id) {
                      data.select = checked;
                    }
                    return data;
                  })
                );

                if (checked) {
                  setCheckId([
                    ...checkId,
                    {
                      id: recipient.id,
                      phone_number: id,
                      name: name,
                      email: value,
                    },
                  ]);
                } else {
                  setCheckId(checkId.filter((item) => item.phone_number !== event.target.id));
                }
              }}
              id={recipient.phone_number}
            />
          </div>
          <p className="mb-1 w-4/12">{recipient.name}</p>
          <p className="mb-1 w-4/12">{recipient.email}</p>
          <p className="mb-1 w-4/12">{recipient.phone_number}</p>
        </div>
      );
    });
  };

  return (
    <>
      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          <div className="border-b pt-4 pb-6">
            <h3 className="text-2xl font-bold text-center">Existing Recipients</h3>
          </div>
          <div className="w-full pr-5 mb-2">
            <div className="relative h-fit flex justify-center items-center max-w-[250px] pt-14 pb-5">
              <input
                name="q"
                type="search"
                className="border-0 px-3 py-2 placeholder-slate-300 text-slate-600 bg-white rounded-l text-sm shadow w-full"
                placeholder="Search Recipients"
                value={filterChange.name}
                onChange={handleFilterChange}
                onKeyUp={onQueryEnter}
              />
              <div
                className="bg-primary text-white font-bold uppercase text px-3 py-2 rounded-r shadow cursor-pointer"
                onClick={onFilterSubmit}
              >
                <i className="fas fa-search" />
              </div>
            </div>

            <div className="items-center w-full bg-transparent border-collapse overflow-hidden">
              <div className="bg-tertiary_gray_two px-3 flex justify-between">
                <div className="text-left py-3 font-bold w-1/12">
                  <input type="checkbox" onChange={handleAllCheckBox} />
                </div>
                <p className="text-left py-3 font-bold w-4/12">Name</p>
                <p className="text-left py-3 font-bold w-4/12">Email</p>
                <p className="text-left py-3 font-bold w-4/12">Phone Number</p>
              </div>

              {renderRecipients(tempRecipients)}
            </div>
            <Pagination
              pageCount={data.total_page}
              itemCount={data.total_items}
              onPageChange={onPageChange}
              showAll={true}
            />
          </div>
        </div>

        <button
          onClick={() => {
            setRecipients([...recipients, ...fixId]);
            onClose();
          }}
          className="py-3 px-5 bg-primary text-white font-bold rounded-lg mb-7 w-fit mx-auto"
        >
          Add Recipients{" "}
          {fixId.length > 0 && (
            <span className="inline-block text-black bg-white px-2 rounded-full ml-1"> {fixId.length}</span>
          )}
        </button>
      </div>
    </>
  );
}
